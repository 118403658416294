import { isBrandPage, isCategoryPage, isSearchPage } from "../common/utils";
import { setPLPMetaData } from "./productListingPageData";
import { bindPLPListeners } from "./productListingPageListener";
import { renderSoldOutItems, renderSoldOutItemsLoader } from "./productListingPageRenderer";

export const initPLP = {
    prePLP: function () {
        if (!isCategoryPage() && !isBrandPage() && !isSearchPage())
            return;

        bindPLPListeners();
        renderSoldOutItemsLoader();
    },
    loadPLP: async function () {
        if (!isCategoryPage() && !isBrandPage() && !isSearchPage())
            return;

        setPLPMetaData();
        renderSoldOutItems();
    }
}

