import { isCheckoutPage, observeSections, showLoadingNotification, showReplicaLoadingSkeleton } from "../common/utils";
import { renderDrawerStores } from "../globalPage/globalPageRenderer";
import { setAvailableStoresCheckout, setStore } from "../globalPage/globalpageData";
import { setCheckoutMetaData } from "./checkoutData";
import { bindCheckoutEvents, checkoutAfterUpdaterListenerInit, checkoutUpdaterListenerInit, loadersInCheckout } from "./checkoutListeners";
import { renderCheckoutCnC, renderGrangeRewards } from "./checkoutRenderer";
import { staticCustomizations } from "../common/globalConstants";
import { customServices } from "../services/customServices";

export const initCheckout = {
    preCheckout: async function () {
        if (!isCheckoutPage()) return;
        checkoutObserver.whenCheckoutPageLoads().then(() => {
            $(".checkout-step--shipping .stepHeader-title").text(staticCustomizations.CheckoutShippingStepHeaderText).css('flex', '12');
        });
        
        observeSections('.checkout-form form #checkoutShippingAddress').then(async function (elm) {
            showLoadingNotification();
            showReplicaLoadingSkeleton();
            await setAvailableStoresCheckout();
            setInterval(() => {
                checkoutUpdaterListenerInit();
            }, 50);
        });

        await renderGrangeRewards();

        const targetNode = document.querySelector(".ReactModalPortal");
        const config = { attributes: true, childList: true, subtree: true };
        const observer = new MutationObserver(async (mutationList, observer) => {
            if($('.ReactModalPortal .optimizedCheckout-orderSummary-cartSection').length > 0 && $('.grange-rewards-checkout').length == 0){
                await renderGrangeRewards();
            }
        });
    
        observer.observe(targetNode, config);
    },
    loadCheckout: async function () {
        if (!isCheckoutPage()) return;

        if (selectedStoreId) {
            await setStore(selectedStoreId, true, true);
        }

        await setAvailableStoresCheckout();
        bindCheckoutEvents();
        await renderCheckoutCnC();
        renderDrawerStores();

        setInterval(() => {
            checkoutAfterUpdaterListenerInit();
        }, 50);
    }
}