import { CheckoutObserver } from "./observer";
import { Popup } from "./popUp";
import { getCookie } from "./utils";

const { staticCustomizations, isLiveSite } = require("./globalConstants");

export const initWindowObj = () => {

    window.bopis = { allStores: [], Customizations: staticCustomizations, SDD: [] };

    window.filteredStoresByAvailability = [];

    window.selectedStoreId = getCookie("storeSelectedIDV3") || null;

    window.isInitialBOPISLoad = true;

    window.checkoutUnavailabilityPopup = new Popup();

    window.checkoutObserver = new CheckoutObserver();

    window.selectedStore = {
        city: null,
        country: null,
        emailAddress: null,
        embededMapUrl: null,
        installationId: null,
        isDefault: null,
        isEnabled: null,
        latitude: null,
        locationId: null,
        longitude: null,
        pickupAllowed: null,
        priority: null,
        shippingAllowed: null,
        state: null,
        storeExternalId: null,
        storeName: null,
        storeType: null,
        street: null,
        timezone: null,
        zipCode: null
    };

    window.productIds = [];

    window.PLPMetaData = {
        products: [
        ]
    }

    window.PDPMetaData = {
        productHasVariants: false,
        selectedProduct: {
            SKU: "",
            quantity: 1
        },
        areRequiredVariantsSelected: false,
        productVariants: [],
    }

    window.cartMetaData = {
        cartProducts: [],
        cart: null
    };

    window.checkoutMetaData = { products: window.checkoutItems || [] };

    window.customerMetaData = {};

    window.pickupStoreFieldID = isLiveSite ? 'field_28' : "field_32";
    window.isDatePickerEnabled = true;
    window.pickupTimeslotFieldID = isLiveSite ? "field_30" : 'field_34';
    window.pickupMethodFieldID = isLiveSite ? "field_32" : 'field_36';
    window.isDeliveryMethodEnabled = true;
    window.isPISMethodEnabled = true;
    window.isSDDMethodEnabled = false;
    window.isShippingStockLocation = true;
    window.shippingStockLocationID = isLiveSite ? "90b65cfd-96b3-4779-bf23-56064c66e594": "828fbceb-9c6e-49a1-8a08-9b8393caf46b";
    window.shippingStockLocationIDs = isLiveSite ? ["90b65cfd-96b3-4779-bf23-56064c66e594"]: ["828fbceb-9c6e-49a1-8a08-9b8393caf46b"];
    window.SDDRadius = 15;
    window.selectedPickupStore = null;
    window.custom__validateGC = true;
    window.custom__viewRewards = true;
    window.isSwal = true;
}