import { renderHeader, renderStoreDrawer } from "../globalPage/globalPageRenderer";
import { graphQLServices } from "../services/graphQLServices";
import { handlePDPAddToCart } from "./productDetailsPageListeners";

const { isPDPPage } = require("../common/utils");
const { getBOPISData } = require("../services/randemRetailServices");
const { initPDP } = require("./productDetailsPage");
const { renderPDPClickAndCollect } = require("./productDetailsPageRenderer");

export async function setPDPMetaData() {
    if (!isPDPPage()) return;
    return new Promise(async resolve => {
        PDPMetaData.selectedProduct.price = BCData.product_attributes.price?.with_tax?.value;
        PDPMetaData.selectedProduct.SKU = $('[data-product-sku]').text() || BCData.product_attributes.sku;
        PDPMetaData.selectedProduct.quantity = $(".form-input--incrementTotal").val() || $(".select-item-quantity").val() || 1

        // If product has variants
        if ($(`[data-product-option-change] .form-field:not([data-product-attribute="swatch"])`).length) {
            PDPMetaData.productHasVariants = true;

            // Check if all required variants are selected
            const $requiredFields = $("[data-product-option-change] [required]");

            if ($requiredFields.length) {
                $requiredFields.each((index, item) => {
                    if (!$(item).val()) {
                        PDPMetaData.areRequiredVariantsSelected = false;
                        return;
                    } else if (index === $requiredFields.length - 1) {
                        PDPMetaData.areRequiredVariantsSelected = true;
                    }
                })
            }

            PDPMetaData.areAllVariantsOptionsPicked = areAllVariantsOptionsPicked();
        }

        PDPMetaData.productInfo = await graphQLServices.getProductDataByIds();
        // PDPMetaData.productInfo = PDPMetaData.productInfo.length > 1 ? PDPMetaData.productInfo[0]:PDPMetaData.productInfo;
        PDPMetaData.isPickupOnly = PDPMetaData.productInfo.find(prodInfo=>prodInfo.customFields.find(cf=>cf.name == 'User Code U4' && (cf.value=='@' || cf.value=='='))) ? true:false;
        PDPMetaData.productVariants = PDPMetaData.productInfo[0].variants;
        resolve();
    })
}

export function areAllVariantsOptionsPicked() {
    if (!isPDPPage()) return;

    var $formFields = $("form[data-cart-item-add] .form-field");
    var areAllVariantsOptionsPicked = true;

    $formFields.each(function (index, item) {
        var formItem;
        if ($(item).attr("data-product-attribute") === "set-radio"
            || $(item).attr("data-product-attribute") === "set-rectangle"
            || $(item).attr("data-product-attribute") === "swatch") {
            formItem = $(item).find(".form-radio:checked");
            if (!formItem.length) {
                areAllVariantsOptionsPicked = false;
            }
        } else if ($(item).attr("data-product-attribute") === "set-select") {
            formItem = $(item).find("select");
            if (!$(formItem).val()) {
                if (formItem) areAllVariantsOptionsPicked = false;
            }
        }
    });

    return areAllVariantsOptionsPicked;
}

export const PDPOnSetStore = () => {
    if (!isPDPPage()) return;

    if (!isInitialBOPISLoad) {
        checkPDPVariantAvailabilityHandler();
    }
}

async function checkPDPVariantAvailabilityHandler() {
    if (!isPDPPage()) return;

    renderPDPClickAndCollect();
    await setPDPMetaData();
}