import { isSearchPage } from "../common/utils";

export const renderSoldOutItemsLoader = () => {
    $(`[data-no-products-notification-custom]`).remove();
    $(`li.product .card-img-container`).addClass(`card-loading-overlay`);
    $(`li.product .card-body`).addClass(`card-loading-overlay`);

}

export const renderSoldOutItems = () => {
    $(`li.product .card-img-container`).removeClass(`card-loading-overlay`);
    $(`li.product .card-body`).removeClass(`card-loading-overlay`);
    $(`[data-sort-by="product"]`).fadeIn();
    $(`[data-no-products-notification-custom]`).remove();

    window.PLPMetaData?.products?.map(item => {
        if (window.location.search?.toLowerCase()?.includes("soldouttags=true")) {
            if (item?.isOutOfStock)
                $(`[ data-test="card-${item.parentId}"]`).find(`.card-body`).append(`<span class="sold-out-tag">Out of Stock</span>`);
        } else {
            if (item?.isOutOfStock)
                $(`[ data-test="card-${item.parentId}"]`).closest(`li.product`).fadeOut();
        }
    })

    if (!window?.PLPMetaData?.products?.find(item => !item.isOutOfStock) && !window.location.search?.toLowerCase()?.includes("soldouttags=true")) {
        if (isSearchPage()) {
            $(`#search-results-heading`).html(`<h1 class="page-heading">
            0 results for 'ofs'</h1>`);
        }
        else {
            let noProductsHTML = `
            <p data-no-products-notification-custom data-no-products-notification="" role="alert" aria-live="assertive" tabindex="-1">
                There are no products available listed under this category.
            </p>
        `;
            $(`.page .page-content#product-listing-container`).prepend(noProductsHTML);

            $(`[data-sort-by="product"]`).fadeOut();
        }

    } else {
        $(`[data-no-products-notification-custom]`).remove();
    }

}