import { isCartPage } from "../common/utils";
import { renderDrawerStores, renderHeader, renderStoreDrawer } from "../globalPage/globalPageRenderer";
import { storefrontServices } from "../services/bigCommerceServices";
import { getBOPISData } from "../services/randemRetailServices";
import { renderCartItemAvailability } from "./cartRenderer";

export const setCartMetaData = async () => {
    if(!isCartPage()) return;
    return new Promise(async resolve => {
        cartMetaData.cartProducts = [];

        cartMetaData.cart = await storefrontServices.getCart();

        console.log("cartMetaData", cartMetaData);

        if (cartMetaData.cart?.length == 0) return;
        cartMetaData.cartItemsProdIDs = cartMetaData.cart ? cartMetaData.cart[0].lineItems.physicalItems.map(item => {
            return item.productId
        }) : [];

        cartMetaData.cartProducts = cartMetaData.cart ? cartMetaData.cart[0].lineItems.physicalItems.map(item => {
            return {
                lineitemId: item.id,
                sku: item.sku,
                productId: item.productId,
                quantity: item.quantity,
                price: item.salePrice,
                name: item.name
            }
        }) : [];

        cartMetaData.checkout = await storefrontServices.getCheckOutInformation(cartMetaData.cart?.[0]?.id);
        resolve();
    })
}

export function cartOnSetStore() {
    if (!isCartPage()) return;
}