import { cartOnSetStore, setCartMetaData } from "../cart/cartData";
import { renderCartItemAvailability } from "../cart/cartRenderer";
import { setCheckoutMetaData } from "../checkout/checkoutData";
import { bindOnGrangeRewards } from "../checkout/checkoutListeners";
import { handlePDPAddToCart } from "../productDetailsPage/productDetailsPageListeners";
import { renderPDPClickAndCollect } from "../productDetailsPage/productDetailsPageRenderer";
import { storefrontServices } from "../services/bigCommerceServices";
import { customServices } from "../services/customServices";
import { graphQLServices } from "../services/graphQLServices";
import { getBOPISData } from "../services/randemRetailServices";
import { renderDrawerStores, renderGrangeRewards, renderHeader, renderStoreDrawer } from "./globalPageRenderer";
const { closeDrawer, getItemAvailability, isCartPage, isCheckoutPage, setCookie, objectsEqual, isAccountPage, bindOnRewardsPage } = require("../common/utils");
const { PDPOnSetStore } = require("../productDetailsPage/productDetailsPageData");
// const { renderDrawerStores } = require("./globalPageRenderer");

export async function setAvailableStoresCheckout() {
    if (!isCheckoutPage() && !isCartPage()) return;

    await waitForBopisWindowObject();

    if(cartMetaData?.cartProducts?.length == 0) await setCheckoutMetaData();

    return new Promise(resolve => {
        window.filteredStoresByAvailability = window.bopis.allStores?.filter(store=>store.pickupAllowed).filter(store => {
            let isStoreValid = window.cartMetaData?.cartProducts?.filter(checkoutProduct => {
                let { availableForShipping, availableForPickup } = getItemAvailability(checkoutProduct.sku, checkoutProduct.quantity, store.locationId);
                return availableForPickup;
            });

            if (isStoreValid?.length == window.cartMetaData?.cartProducts?.length)
                return (isStoreValid);
        });
        resolve()
    })

}

async function waitForBopisWindowObject() {
    return new Promise(resolve => {
        const waitForBopisWindowObjectInterval = setInterval(() => {
            if (window.bopis?.allStores?.length) {
                clearInterval(waitForBopisWindowObjectInterval);
                resolve();
            }
        }, 20);
    })
}

export async function setStore(storeId = "", isCloseDrawer = true, skipBopisCall = false) {
    return new Promise(async resolve => {
        setCookie('storeSelectedIDV3', storeId, 365);
        selectedStoreId = storeId;
        selectedStore = bopis?.allStores?.find(store => storeId === store.locationId) || {};

        renderStoreDrawer()
        PDPOnSetStore();
        cartOnSetStore();

        if (isCloseDrawer) {
            closeDrawer();
        }

        if (!skipBopisCall) {
            await getBOPISData();
            selectedStore = bopis?.allStores?.find(store => storeId === store.locationId) || {};
        }

        handlePDPAddToCart();
        PDPOnSetStore()

        renderCartItemAvailability();
        renderHeader();
        renderStoreDrawer();
        resolve();
    })
}

export function setStoreHours(storeOperatingHours, storeid = 0) {
    if (!storeOperatingHours) return;
    var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    //add day when no day found
    for (let i = 0; i < days.length - 1; i++) {
        var searchDay = storeOperatingHours?.filter(function (el) { return days[i] == el.day; });
        if (searchDay?.length == 0) {
            storeOperatingHours?.splice(i, 0, { day: days[i], operatingHours: [] });
        }
    }

    //Monday first
    var first = storeOperatingHours?.[0]
    storeOperatingHours = storeOperatingHours?.concat(first);
    storeOperatingHours?.shift();

    var toDelete = [];
    for (let i = 0; i < storeOperatingHours?.length - 1; i++) {
        if (storeid.length > 0) {
        }
        if (objectsEqual(storeOperatingHours[i].operatingHours, storeOperatingHours[i + 1].operatingHours) || storeOperatingHours[i].operatingHours.length == 0 && storeOperatingHours[i + 1].operatingHours == 0) {
            toDelete.push(storeOperatingHours[i]);
        }
    }

    var results = storeOperatingHours.filter(({ operatingHours: id1 }) => !toDelete.some(({ operatingHours: id2 }) => id2 === id1));
    if (storeid.length > 0) {
    }
    var daysOpen = [];
    var last_index = 0;
    days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    for (let index = 0; index <= results.length - 1; index++) {
        if (storeid.length > 0) {
        }
        if (results[index].day !== days[index]) {
            if (days[last_index] == results[index].day) {
                daysOpen.push({ day: days[last_index].substring(0, 3), operatingHours: results[index].operatingHours })
            } else {
                var existingDay = daysOpen.filter(function (el) { return el.day.indexOf(days[last_index]) >= 0 });
                if (existingDay.length == 0) {
                    daysOpen.push({ day: days[last_index].substring(0, 3) + ' - ' + results[index].day.substring(0, 3), operatingHours: results[index].operatingHours })
                } else {
                    daysOpen.push({ day: days[index].substring(0, 3) + ' - ' + results[index].day.substring(0, 3), operatingHours: results[index].operatingHours })
                }
            }
            last_index = days.indexOf(results[index].day) + 1;
            
        } else {
            daysOpen.push({ day: results[index].day.substring(0, 3), operatingHours: results[index].operatingHours })
            last_index = days.indexOf(results[index].day) + 1;
        }
    }

    if (storeid.length > 0) {
    }

    return daysOpen;
}

export async function setGrangeRewards(){
    if(!isAccountPage() || !custom__viewRewards) return;
    let grangeRewardsDetails = await customServices.getGrangeReward(customerMetaData.email);
    renderGrangeRewards(grangeRewardsDetails);
}

export async function setCustomerMetaData(){
    return new Promise(async (resolve) => {
        customerMetaData = await graphQLServices.getCustomer();
        if(customerMetaData){
            customerMetaData.grangeRewardsDetails = await customServices.getGrangeReward(customerMetaData.email);
            if(customerMetaData.grangeRewardsDetails?.grangeRewardDollarAmount != "0.00") bindOnGrangeRewards();
        }
        resolve();
    })
}

export async function checkCartItemsIfWithGC(){
    if(!isCartPage() && !isCheckoutPage()) return;
    return new Promise(async (resolve) => {
        let cartItems = checkoutMetaData.cart.lineItems;
        let cartItems_GC = checkoutMetaData.cart.lineItems.giftCertificates;

        let hasMixedCart = cartItems_GC.length > 1 || (cartItems_GC.length >=1 && (cartItems.physicalItems.length >=1 || cartItems.customItems.length >=1 || cartItems.digitalItems.length >=1));

        if(hasMixedCart == true){
            if(isCartPage()) {
                $(`<div class="halo-cart-notification">
                    <span class="icon"><svg><use xlink:href="#icon-info"></use></svg></span>
                    <span class="text">One digital gift card must be the only item in the cart.</span>
                </div>`).insertAfter($('.page-cart .page-header'));
                $('.cart-additionalCheckoutButtons').hide();
            }

            if(checkoutMetaData.checkout.consignments.length > 0) await storefrontServices.deleteConsignment(checkoutMetaData.checkout.id, checkoutMetaData.checkout.consignments[0].id);

            if(isCheckoutPage()) {
                $('body').attr('style', 'overflow: hidden;');
                setTimeout(()=>{
                    swal.fire({
                        title: "",
                        html: `One digital gift card must be the only item in the cart.`,
                        icon: 'info',
                        confirmButtonColor: '#ec2639',
                        confirmButtonText: 'Go to Cart',
                        allowEscapeKey: false,
                        allowOutsideClick: false
                    }).then(function(result) {
                        if (result.value && result.value === true) {
                            $('body').attr('style', 'pointer-events: none; overflow: hidden;');
                            location.href = '/cart.php';   
                        }
                    });
                }, 2000)
                return;
            }

        }
        resolve();
    })
}