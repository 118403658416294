export class CheckoutObserver {
    whenCheckoutPageLoads() {
        return new Promise((resolve, reject) => {
            let interval = setInterval(function () {
                if ($(`.checkout-step--shipping`).length) {
                    clearInterval(interval);
                    resolve();
                }
            }, 50);
        });
    }

    whenCheckoutShippingLoads() {
        return new Promise((resolve, reject) => {
            let interval = setInterval(function () {
                if ($(`.checkout-step--shipping .checkout-form ul.form-checklist.optimizedCheckout-form-checklist`).length) {
                    clearInterval(interval);
                    resolve();
                }
            }, 50);
        });
    }

    checkBillingSameAsShipping() {
        if (!$("#sameAsBilling").is(':checked'))
            document.getElementById("sameAsBilling").click();
    }

    unCheckBillingSameAsShipping() {
        let waitFormSameAsShippingCheckBox = setInterval(() => {
            if ($("#sameAsBilling").is(':checked') && $("#cnc__shipping-pickup.active").length) {
                clearInterval(waitFormSameAsShippingCheckBox);
                document.getElementById("sameAsBilling").click();
            }
        }, 250);
    }

    unCheckSaveShippingAsAddress() {
        let waitForSaveAsShippingCheckBox = setInterval(() => {
            if ($("[name='shippingAddress.shouldSaveAddress']").is(':checked') && $("#cnc__shipping-pickup.active").length) {
                clearInterval(waitForSaveAsShippingCheckBox);
                document.getElementById("shippingAddress.shouldSaveAddress").click();
            }
        }, 250);
    }

    isShippingAddressOpened() {
        if (
            $(".checkout-step--shipping .checkout-address .dynamic-form-field").length ||
            $(".checkout-step--shipping .checkout-step-info").length ||
            $(`.checkout-step--shipping [aria-controls="addressDropdown"]`).length
        ) {
            return true;
        } else {
            return false;
        }
    }

    changeValue(element, value) {
        try {
            var nativeInputValueSetter = Object.getOwnPropertyDescriptor(
                window.HTMLInputElement.prototype,
                "value"
            ).set;
            nativeInputValueSetter.call(element, value);
            var inputEvent = new Event("input", {
                bubbles: true
            });
            element.dispatchEvent(inputEvent);
        } catch (e) {
            console.log(e);
        }
    }

    changeSelectValue(element, selectedValue) {
        try {
            element.value = selectedValue;
            var nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLSelectElement.prototype, "value").set;
            nativeInputValueSetter.call(element, selectedValue);
            var ev2 = new Event('change', {
                bubbles: true
            });
            element.dispatchEvent(ev2);
        } catch (e) {
            console.log(e);
        }
    }
}