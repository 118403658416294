
import { api_baseurl, sdd_api_baseurl, applicationId, staticCustomizations } from "../common/globalConstants";
import { isBrandPage, isCartPage, isCategoryPage, isCheckoutPage, isPDPPage, isSearchPage, transformObject } from "../common/utils";
import { storefrontServices } from "./bigCommerceServices";
import { graphQLServices } from "./graphQLServices";


export const getBOPISData = async () => {
    // bopisServiceLoaded = false;
    let BOPISReqData;
    window.bopis_response = null;

    BOPISReqData = {
        SKUs: [],
        selectedStoreId: selectedStoreId || null
    };

    if (isPDPPage()) {
        if (PDPMetaData.productHasVariants == true) {
            BOPISReqData.SKUs = PDPMetaData.productVariants.map(graph => {
                return { SKU: graph.sku, quantity: 1 }
            });
        } else {
            BOPISReqData.SKUs.push({
                SKU: document.querySelector("[data-product-sku]").textContent,
                quantity: $('.form-input--incrementTotal').val()
            });
        }
    }

    if (isCategoryPage() || isBrandPage() || isSearchPage()) {
        const $allCardElements = $(`[data-test]`);

        window.productIds = [];

        $allCardElements.each((index, elem) => {
            window.productIds.push({ productId: parseInt($(elem).data("test")?.replace(`card-`, ``)) });
        })

        // window.productIds = window.productList?.map(item => {
        //     return {
        //         productId: item.id
        //     }
        // });

        let allProductsSKUs = await graphQLServices.getProductDataByIds();

        allProductsSKUs?.data?.site?.products?.edges?.map(item => {
            window.PLPMetaData.products.push(
                {
                    parentId: item?.node.entityId,
                    sku: item?.node?.sku,
                    isOutOfStock: true,
                    variants: []
                }
            )

            if (item?.node?.variants?.edges?.length) {
                item?.node?.variants?.edges?.map(iv => {
                    let PLPMetaDataIndex = window.PLPMetaData.products.findIndex(i => i.parentId == item?.node?.entityId);
                    window.PLPMetaData.products[PLPMetaDataIndex].variants.push({
                        sku: iv?.node?.sku,
                        hasStock: false
                    })

                    BOPISReqData.SKUs.push({
                        SKU: iv?.node?.sku,
                        quantity: 1
                    });
                })
            } else {
                BOPISReqData.SKUs.push({
                    SKU: item?.node?.sku,
                    quantity: 1
                });
            }
        })

        console.log("allProductsSKUs", allProductsSKUs)

    }

    if (isCartPage() || isCheckoutPage()) {
        if (checkoutMetaData.products == 0) {
            BOPISReqData.SKUs = [];
        } else {
            BOPISReqData.SKUs = checkoutMetaData.products?.map(item => {
                return { SKU: item.sku, quantity: item.quantity.toString() }
            });
        }
    }

    return new Promise((resolve, reject) => {
        $.post({
            url: `${api_baseurl}location`,
            contentType: "application/json; charset=utf-8",
            data: JSON.stringify(BOPISReqData),
            headers: {
                "x-randem-application-id": applicationId
            }
        }).then((bopisRes) => {
            bopis = bopisRes;
            bopis.Customizations = staticCustomizations;
            // bopisServiceLoaded = true;
            resolve(bopis);
        });
    });
}

export const checkSDDZone = async (address) => {
    let Skus = '';
    if(isPDPPage()){
        Skus = `&Skus=${PDPMetaData.selectedProduct.SKU}`;
    }
    return new Promise((resolve, reject) => {
        $.post({
            url: sdd_api_baseurl + `?destination=${address}&IsSameDayDelivery=true&radius=${SDDRadius}&measure=mi${Skus}`,
            contentType: "application/json; charset=utf-8",
            headers: {
                "x-randem-application-id": applicationId
            }
        }).then((bopisRes) => {
            bopis.SDD = bopisRes;
            // bopisServiceLoaded = true;
            resolve(bopis);
        });
    });
}