import { isCartPage, isCheckoutPage, isPDPPage } from "../common/utils";

export const graphQLServices = {
    getProductDataByIds: async function (productIds = []) {
        let productInfoResponse = [];

        if(productIds.length == 0){
            if (isPDPPage()) {
                productIds.push($('[name="product_id"]').val());
            }
            if (isCheckoutPage() || isCartPage()) {
                productIds = checkoutMetaData.products.map(pr => pr.productId);
            }
        }

        if(productIds.length == 0) return productInfoResponse;

        let response = await graphQLServices.getProductDataByIdsPaginated(productIds);
        let refinedResponse = await graphQLServices.graphQLToJavascript(response);
        productInfoResponse.push(...refinedResponse.products);

        let hasNextPage = response.data.site.products.pageInfo.hasNextPage;
        let afterPage = response.data.site.products.pageInfo.endCursor;
        
        while (hasNextPage == true) {
            let response_ = await graphQLServices.getProductDataByIdsPaginated(productIds, afterPage);

            let refinedResponse = await graphQLServices.graphQLToJavascript(response_);
            productInfoResponse.push(...refinedResponse.products);

            afterPage = response_.data.site.products.pageInfo.endCursor
            hasNextPage = response_.data.site.products.pageInfo.hasNextPage;
        }

        return productInfoResponse;
    },
    getProductDataByIdsPaginated: async function (productIds, pagination = "") {
        return new Promise((resolve, reject) => {
            let postData = JSON.stringify({
                query: `
                query MultipleProduct {
                    site {
                        products (entityIds: [${productIds}], first: 10, after: "${pagination}") {
                        pageInfo {
                            startCursor
                            endCursor
                            hasNextPage
                            hasPreviousPage
                        }
                        edges {
                            node {
                            id
                            entityId
                            sku
                            name
                            brand {
                                id
                                entityId
                                name 
                            }
                            customFields {
                                edges {
                                  node {
                                    entityId
                                    name
                                    value
                                  }
                                }
                            }
                            variants(first: 100) {
                                edges {
                                node {
                                    sku
                                        productOptions {
                                            edges {
                                            node {
                                                entityId
                                                displayName
                                                isRequired
                                                ... on CheckboxOption {
                                                checkedByDefault
                                                }
                                                ... on MultipleChoiceOption {
                                                values(first: 10) {
                                                    edges {
                                                    node {
                                                        entityId
                                                        label
                                                        isDefault
                                                        ... on SwatchOptionValue {
                                                        hexColors
                                                        imageUrl(width: 200)
                                                        }
                                                    }
                                                    }
                                                }
                                                }
                                            }
                                            }
                                        }
                                            
                                }
                                }
                            }
                            options{
                                edges {
                                    node {
                                        entityId
                                        isRequired
                                        values {
                                            edges {
                                                node {
                                                    entityId
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            }
                        }
                        }
                    }
                }`,
            })

            $.ajax({
                url: "/graphql",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer '+api_token,
                },
                method: "POST",
                dataType: "json",
                data: postData,
            }).then((b2bResponseData) => {
                resolve(b2bResponseData)
            }).always((err) => {
                resolve(err);
            });

        });
    },
    graphQLToJavascript: function (graphQLResult) {
        var root;
        if (graphQLResult.data.site.route) {
            root = graphQLResult.data.site.route.node
        } else if (graphQLResult.data.site.products) {
            root = graphQLResult.data.site.products;
        }
        var filteredResult = graphQLServices.removeNodeAndEdges(root, null);

        if (graphQLResult.data.site.products.pageInfo) {
            var final = {};
            final["products"] = filteredResult;
            final["pageInfo"] = graphQLResult.data.site.products.pageInfo;
            return final;
        }

        return filteredResult;
    },
    removeNodeAndEdges: function (root, parent) {
        Object.keys(root).forEach(function (key) {
            if (typeof root[key] == "object" && root[key]) {
                if (Array.isArray(root[key])) {
                    root = root[key];
                    root = root.map(function (entry) {
                        return entry.node;
                    });
                    root = graphQLServices.removeNodeAndEdges(root, null);
                } else {
                    root[key] = graphQLServices.removeNodeAndEdges(root[key], root);
                }
            }
        });
        return root;
    },
    getCustomer: function() {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: "/graphql",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer '+api_token,
                },
                method: "POST",
                dataType: "json",
                data: JSON.stringify({ query: 'query customer { customer { entityId company customerGroupId email firstName lastName notes } }',
            }),
            }).then((b2bResponseData) => {
                resolve(b2bResponseData?.data?.customer)
            }).always((err) => {
                resolve(err);
            });
        });
    }
}