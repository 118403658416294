import { bindOnRewardsPage } from "../common/utils";
import { setGrangeRewards, setStore } from "./globalpageData";
const { initHeaders } = require("../common/initHeaders");
const { bindHeaderAndDrawerEventListeners } = require("./globalPageListeners");
const { renderHeader, renderStoreDrawer, renderDrawerStores, showCNC } = require("./globalPageRenderer");

export const initGlobal = {
    preHeader: function () {
        initHeaders();
        renderHeader();
    },
    loadHeader: async function () {
        return new Promise(async resolve => {
            if (selectedStoreId) {
                await setStore(selectedStoreId, true, true);
            }

            renderHeader();
            renderStoreDrawer();
            bindHeaderAndDrawerEventListeners();
            showCNC();
            setGrangeRewards();
            bindOnRewardsPage();
            resolve();
        })

    }
}
