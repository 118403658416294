import { render } from "./utils";

export class Popup {
    settings;
    popupUniqueId;

    constructor() {
        this.popupUniqueId = this.generateUniqueId();
        let checkHTMLBody = setInterval(()=>{
            if($("body").length > 0){
                clearInterval(checkHTMLBody);
                $("body").append(`<div id="${this.popupUniqueId}" class="notice-popup"></div>`);
            }
        })
    }

    prepare(settings = {
        headerText: "",
        contentHTML: "",
        button1Text: "",
        button2Text: "",
        onButton1Click: null,
        onButton2Click: null,
        customClass:""
    }) {
        this.settings = settings;

        let HtmlToRender = `
        <div class="notice-popup-underlay">
            <div class="popup-notice ${settings.customClass}">
                ${settings.headerText.length > 0 ?
                `<div class="popup-title">
                        <h4>${settings.headerText}</h4>
                    </div>`: ``
            }
                

                <div class="popup-content">
                    ${settings.contentHTML}

                    ${settings.button1Text || settings.button2Text ? `
                        <div class="popup-buttons">
                            ${settings.button1Text ? `<button type="button" class="notice-btn" id="notice-btn-1">${settings.button1Text}</button>` : ""}
                        
                            ${settings.button2Text ? `<button type="button" class="notice-btn" id="notice-btn-2">${settings.button2Text}</button>` : ""}
                        </div>
                        ` : ""}
                </div>
            </div>
        </div>
        `;

        if (settings.onButton1Click) {
            $("body").on("click", `#${this.popupUniqueId} #notice-btn-1`, () => {
                settings.onButton1Click();
            });
        }

        if (settings.onButton2Click) {
            $("body").on("click", `#${this.popupUniqueId} #notice-btn-2`, () => {
                settings.onButton2Click();
            });
        }

        render("#" + this.popupUniqueId, HtmlToRender);
    }

    open() {
        $(`#${this.popupUniqueId}`).fadeIn(250);
        $("body").css("overflow", "hidden");
    }

    close() {
        $(`#${this.popupUniqueId}`).fadeOut(250);
        $("body").css("overflow", "auto");
    }

    generateUniqueId() {
        return Math.random().toString(36).substring(2) +
            (new Date()).getTime().toString(36);
    }
}