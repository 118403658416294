import { staticCustomizations } from "../common/globalConstants";
import { getItemAvailability, isCartPage } from "../common/utils";

export const renderCartItemAvailability = () => {
    if (!isCartPage() || checkoutMetaData.cart.lineItems.physicalItems.length == 0) return;

    $(".cnc__cart-item-availability-wrapper").remove();

    let HtmlToRender = ``;

    if (!checkoutMetaData.products.length) {
        HtmlToRender = ` <div class="placeholder_content cnc__cart-item-availability-wrapper">
        <div class="placeholder_item"></div>
    </div>`;

        let $allProductElements = $(`.cart-item-name`);

        $allProductElements.each((index, item) => {
            $(item).before(HtmlToRender);
        })

    } else {
        $(".cnc__cart-item-availability-wrapper").remove();
        const selectedStoreIdInfo = bopis?.allStores?.find(store => store?.locationId == selectedStoreId);
        checkoutMetaData.products.forEach(product => {
            const { availableForShipping, availableForPickup } = getItemAvailability(product.sku, product.quantity, selectedStoreId, product.price)
            let htmlForShipping = checkoutMetaData.pickupOnlyProducts.find(po=>po.sku == product.sku) ? 
                `<p class="cnc__shipping-availability cnc__color-unavailable">Not Available for Shipping</p>`
                :availableForShipping ? 
                    `<p class="cnc__shipping-availability cnc__color-available">${staticCustomizations.CartAvailInDel}</p>` 
                    : `<p class="cnc__shipping-availability cnc__color-unavailable">${staticCustomizations.CartOOSInDel}</p>`;
            let currentStock = bopis?.perSKU?.find(per => per.storeId == selectedStoreId && per.sku === product.sku)?.quantity;
            HtmlToRender = `
                <div class="cnc__cart-item-availability-wrapper">
                ${selectedStoreId ? availableForPickup
                    ? htmlForShipping + `<p class="cnc__pickup-availability cnc__color-available">${staticCustomizations.CartAvailInPIS} in ${selectedStoreIdInfo?.storeName} Store</p>`
                    : htmlForShipping + `<p class="cnc__pickup-availability cnc__color-unavailable">${staticCustomizations.CartOOSInPIS} ${selectedStoreIdInfo?.storeName} Store</p>`
                    : htmlForShipping}
                </div >
            `;

            $(`[data-cart-itemid="${product.lineitemId}"]`).parents('tr').find('.cart-item-name').before(HtmlToRender + '<p style="display:none;">current stock:' + currentStock + '</p>')

        });
    }
}