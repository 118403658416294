const { setStore } = require("./globalpageData");
const { closeDrawer, openDrawer, setCookie, isCheckoutPage } = require("../common/utils");
const { renderDrawerStores } = require("./globalPageRenderer");

export function bindHeaderAndDrawerEventListeners() {
    bindCloseDrawerOnUnderlayClick();
    bindCloseDrawerOnDrawerXClick();
    bindDrawerStoreSearchByInfo();
    bindDrawerSetStore();
    bindToggleStoreMoreInfo();
    bindDrawerStoreSearchByLocation();
    bindOnSearchXClick();
    bindDrawerViewMoreInfo();
    bindOnSelectStoreClick();
    // TODO remove this later
    bindDrawerDeselectStore();
}

function bindCloseDrawerOnUnderlayClick() {
    $("body").on("click", ".cnc__drawer-underlay", () => {
        closeDrawer();
    });
}

function bindCloseDrawerOnDrawerXClick() {
    $("body").on("click", "#cnc__drawer-close-btn", () => {
        closeDrawer();
    });
}

function showDynamicSearchboxIcon($input, isLoading) {
    if (isLoading) {
        $(".cnc__search-input-wrapper .cnc__close-icon").hide();
        $(".cnc__search-input-wrapper .cnc__search-icon").hide();
        $(".cnc__search-input-wrapper .cnc__ripple-loader").show();
        return;
    }

    if ($input.val()) {
        $(".cnc__search-input-wrapper .cnc__close-icon").show();
        $(".cnc__search-input-wrapper .cnc__search-icon").hide();
        $(".cnc__search-input-wrapper .cnc__ripple-loader").hide();
    } else {
        $(".cnc__search-input-wrapper .cnc__close-icon").hide();
        $(".cnc__search-input-wrapper .cnc__search-icon").show();
        $(".cnc__search-input-wrapper .cnc__ripple-loader").hide();
    }
}

function bindDrawerStoreSearchByInfo() {
    $("body").on("keyup", "#cnc__store-search-input-from-list", function () {
        let searchInputVal = $("#cnc__store-search-input-from-list").val();
        let filteredStores = [];
        if (!searchInputVal) {
            renderDrawerStores();
            showDynamicSearchboxIcon($(this));

        } else {
            filteredStores = bopis?.allStores.filter(store =>
                store.storeName.toLowerCase().includes(searchInputVal) ||
                store.city.toLowerCase().includes(searchInputVal) ||
                store.state.toLowerCase().includes(searchInputVal) ||
                store.country.toLowerCase().includes(searchInputVal) ||
                store.zipCode.toLowerCase().includes(searchInputVal)
            );
            renderDrawerStores(filteredStores);
            showDynamicSearchboxIcon($(this));
        }
    });
}

function bindDrawerSetStore() {
    if(isCheckoutPage()) return;
    $("body").on("click", ".cnc__set-store-btn", function (e) {
        e.preventDefault();
        const storeId = $(this).attr("store-id");
        setStore(storeId);
    });
}

function bindToggleStoreMoreInfo() {
    $("body").on("click", ".cnc__view-store-details-btn", function () {
        $(this).closest(".store").find(".cnc__store-more-info").slideToggle(200);
    });
}

function bindDrawerStoreSearchByLocation() {
    $("body").on("keyup", "#cnc__store-search-input-closest", function () {
        handleOnSearchByLocationChange();
    });

    $("body").on("click", ".cnc__use-location a", function (e) {
        e.preventDefault();
        getCurrentLocation();

    });

}

function getCurrentLocation() {
    navigator.geolocation.getCurrentPosition(function (position) {
        var myLat = position.coords.latitude;
        var myLon = position.coords.longitude;
        $('body').attr('mylat', myLat);
        $('body').attr('mylon', myLon);
        setCookie('myLat', myLat, 7);
        setCookie('myLon', myLon, 7);

        setSelectedStoreByDistance(myLat, myLon);

    },
        function (error) {
        });
}

function sortStoresByDistance() {
    bopis?.allStores.sort(function (a, b) {
        return parseFloat(a.distance) - parseFloat(b.distance);
    });
}

function setSelectedStoreByDistance(myLat, myLon) {
    var allDistances = [];
    var allIDs = [];
    var allIDNames = [];
    var nearestStoreID;
    var nearestStoreName;

    bopis?.allStores.forEach(function (el, index) {
        el.distance = distance(myLat, myLon, el.latitude, el.longitude);
        var D = parseInt(el.distance);
        if (allDistances.indexOf(D) < 0) {
            allDistances.push(D);
            allIDNames.push(el.storeName);
            allIDs.push(el.locationId);
        }

    });

    sortStoresByDistance();

    if (allDistances.length > 0) {
        let nearestDistance = allDistances[0];
        for (var i = 0; i < allDistances.length; ++i) {
            if (allDistances[i] < nearestDistance) {
                nearestDistance = allDistances[i];
            }
        }

        for (var i = 0; i < allDistances.length; i++) {
            if (allDistances[i] == nearestDistance) {
                nearestStoreID = allIDs[i];
                nearestStoreName = allIDNames[i];
            }
        }

        setStore(nearestStoreID, false, true);
    }

    renderDrawerStores();
}

function distance(lat1, lon1, lat2, lon2) {
    var R = 6371; // km (convert this constant to get miles)
    var dLat = (lat2 - lat1) * Math.PI / 180;
    var dLon = (lon2 - lon1) * Math.PI / 180;
    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return Math.round(d);
}

function bindOnSearchXClick() {
    $("body").on("click", ".cnc__search-input-wrapper .cnc__close-icon-wrapper", function () {
        let $input = $(this).siblings("input[type='text']");
        $input.val("");

        if ($input.attr("id") === "cnc__store-search-input-closest") {
            handleOnSearchByLocationChange();
        } else if ($input.attr("id") === "cnc__store-search-input-from-list") {
            $input.trigger("keyup");
        }
    });
}

function bindDrawerViewMoreInfo() {
    $("body").on("click", ".cnc__view-more-store-info", (event) => {
        $(event.currentTarget).closest(".store ").find(".cnc__store-more-info").slideDown();
        $(event.currentTarget).addClass("is-open");
        $(event.currentTarget).parent().addClass("is-open");
        $(event.currentTarget).text('Less Details');
    })

    $("body").on("click", ".cnc__view-more-store-info.is-open", (event) => {
        $(event.currentTarget).closest(".store ").find(".cnc__store-more-info").slideUp();
        $(event.currentTarget).removeClass("is-open");
        $(event.currentTarget).parent().removeClass("is-open");
        $(event.currentTarget).text('View Store Details');
    })
}

function bindDrawerDeselectStore() {
    $("body").on("click", "#deselect-store", async () => {
        await setStore("");
    });
}

function handleOnSearchByLocationChange() {
    const $input = $("#cnc__store-search-input-closest");
    let searchInputVal = $input.val();

    if (!searchInputVal) {
        renderDrawerStores();
        showDynamicSearchboxIcon($input);
    } else {
        clearTimeout(timeout);

        timeout = setTimeout(() => {
            // Making a deep copy of the allStores obj
            let filteredStores = JSON.parse(JSON.stringify(bopis?.allStores));
            // 

            showDynamicSearchboxIcon($input);

            renderDrawerStores(filteredStores);
        }, 300);
    }
}

function bindOnSelectStoreClick() {
    $("body").on("click", ".cnc__select-store, .cnc__header-store-selector, .change-selected-store", () => {
        openDrawer();
    })
}