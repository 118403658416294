
export const storefrontServices = {
    updateCustomerMessage: async function (checkoutId, customerMessage) {
        sessionStorage.setItem("orderComments", customerMessage.customerMessage);

        return new Promise((resolve, reject) => {
            $.ajax({
                url: `/api/storefront/checkouts/${checkoutId}`,
                method: "PUT",
                data: JSON.stringify(customerMessage)
            })
                .then((consignmentRes) => {
                    resolve(consignmentRes)
                })
                .catch(() => {
                    console.log(`Something went wrong while updating order comments. Please try again later.`);
                })
        });
    },

    createConsignment: async function (checkoutId) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: `/api/storefront/checkouts/${checkoutId}/consignments?include=consignments.availableShippingOptions&randem=1`,
                method: "POST",
                data: JSON.stringify(consignmentPostData)
            })
                .then((consignmentRes) => {
                    resolve(consignmentRes)
                })
                .catch(() => {
                    console.log(`Something went wrong while createConsignment. Please try again later.`);
                })
        });
    },

    updateConsignment: async function (checkoutId, consignmentId, consignmentPostData) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: `/api/storefront/checkouts/${checkoutId}/consignments/${consignmentId}?include=consignments.availableShippingOptions&randem=1`,
                headers: {
                    "Content-Type": "application/json"
                },
                method: "PUT",
                data: JSON.stringify(consignmentPostData)
            })
                .then((consignmentRes) => {
                    resolve(consignmentRes)
                })
                .catch(() => {
                    console.log(`Something went wrong while updating consignment. Please try again later.`);
                })
        });
    },

    getCheckOutInformation: async function (checkoutId) {
        return new Promise((resolve, reject) => {
            $.get(`/api/storefront/checkouts/${checkoutId}`).then(checkOutInfoRes => {
                resolve(checkOutInfoRes);
                return;
            }).catch((err) => {
                reject(err)
            });
        });
    },

    getCart: async function () {
        return new Promise((resolve, reject) => {
            $.get(`/api/storefront/carts`).then(checkOutInfoRes => {
                resolve(checkOutInfoRes);
                return;
            }).catch((err) => {
                reject(err)
            });
        });
    },

    removeConsignment: async function () {
        let cartItemDetails = await storefrontServices.getCart();

        let checkoutDetails = await storefrontServices.getCheckOutInformation(cartItemDetails[0].id);

        if (checkoutDetails.consignments.length > 0) {
            if (checkoutDetails.consignments[0].shippingAddress?.firstName.length == 0) return;
            var products = [];
            var productObject = {};
            cartItemDetails[0].lineItems.physicalItems.forEach(function (item) {
                productObject.itemId = item.id;
                productObject.quantity = item.quantity;
                products.push(productObject);
            });

            var data = {
                "address": {
                    "country_code": "US",
                    "first_name": "",
                    "last_name": ""
                },
                "lineItems": products
            }

            await storefrontServices.updateConsignment(cartItemDetails[0].id, checkoutDetails.consignments[0].id, data);
        }

    },

    deleteAllConsignment: async function () {
        return new Promise(async (resolve, reject) => {
            let cartItemDetails = await storefrontServices.getCart();

            let checkoutDetails = await storefrontServices.getCheckOutInformation(cartItemDetails[0].id);

            if(checkoutDetails.consignments.length > 0){
                let consignments = checkoutDetails.consignments;
                for (con of consignments) {
                    await storefrontServices.deleteConsignment(cartItemDetails[0].id, con.id);
                }
            }

            resolve();
        });
    },

    deleteConsignment: async function (checkoutId, consignmentId) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: `/api/storefront/checkouts/${checkoutId}/consignments/${consignmentId}?randem=1`,
                method: "DELETE"
            })
                .then((consignmentRes) => {
                    resolve(consignmentRes)
                })
                .catch(() => {
                    console.log(`Something went wrong while deleting consignment. Please try again later.`)
                })
        });
    },

    updateShippingAddress: async function (checkoutId, consignmentPostData) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: `/api/storefront/checkouts/${checkoutId}/consignments?include=consignments.availableShippingOptions&randem=1`,
                headers: {
                    "Content-Type": "application/json"
                },
                method: "POST",
                data: JSON.stringify(consignmentPostData)
            })
                .then((consignmentRes) => {
                    resolve(consignmentRes)
                })
                .catch(() => {
                    console.log(`Something went wrong while updateShippingAddress. Please try again later.`);
                })
        });
    },

    getProductSKU: async function (productId, postData) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: `/remote/v1/product-attributes/${productId}`,
                method: "POST",
                data: postData
            })
                .then((productRes) => {
                    resolve(productRes)
                })
                .catch(() => {
                })
        });
    },

    getCustomerDetails: async function () {
        var query = { query: 'query customer { customer { entityId company customerGroupId email firstName lastName notes } }', };
        return new Promise((resolve, reject) => {
            $.ajax({
                url: "/graphql",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + api_token,
                },
                method: "POST",
                dataType: "json",
                data: JSON.stringify(query),
            })
                .then((updateResponse) => {
                    resolve(updateResponse);
                })
                .catch(() => {
                })
        });
    },

}