import { getBOPISData } from "../services/randemRetailServices";
import { setPLPMetaData } from "./productListingPageData";
import { renderSoldOutItems, renderSoldOutItemsLoader } from "./productListingPageRenderer";


export const bindPLPListeners = () => {
    // $(`body`).on(`click`, `.pagination-link`, () => {
    //     resetPLPLoader();
    // });

    // $(`body`).on(`click`, `.navList-item .navList-action`, () => {
    //     resetPLPLoader();
    // });

    // $(`a.facetedSearch-clearLink`).on(`click`, () => {
    //     console.log("clear field clicked");
    //     resetPLPLoader();
    // });

    (function (history) {
        const pushState = history.pushState;
        history.pushState = function (state) {
            if (typeof history.onpushstate == "function") {
                history.onpushstate({ state: state });
            }
            // Call your custom function here
            resetPLPLoader();
            return pushState.apply(history, arguments);
        }
    })(window.history);

    // const targetNode = document.querySelector(".facetedSearch-refineFilters");
    // const config = { attributes: true, childList: true, subtree: true };
    // const observer = new MutationObserver((mutationList, observer) => {
    //     resetPLPLoader();
    // });

    // observer.observe(targetNode, config);
}

const resetPLPLoader = async () => {
    window.PLPMetaData = {
        products: [
        ]
    };

    setTimeout(async () => {
        renderSoldOutItemsLoader();
        await getBOPISData();
        setPLPMetaData();
        renderSoldOutItems();
    }, 1500);
}