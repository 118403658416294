export function isCheckoutPage() {
    if (location.pathname.includes("/checkout")) return true;
    else return false;
}

export function isPDPPage() {
    if (BCData.product_attributes) return true;
    else return false;
}

export function isCartPage() {
    if (location.pathname.includes("/cart.php")) {
        return true;
    } else {
        return false;
    }
}

export function isCategoryPage() {
    if (window.BC_page_type == "category") {
        return true;
    } else {
        return false
    }
}

export function isBrandPage() {
    if (window.BC_page_type == "brand") {
        return true;
    } else {
        return false
    }
}

export function isSearchPage() {
    if (window.BC_page_type == "search") {
        return true;
    } else {
        return false
    }
}

export function isOrderConfirmationPage() {
    if (location.pathname.includes("/checout/order-confirmation")) {
        return true;
    } else {
        return false;
    }

}

export function getItemAvailability(itemSKU, quantity, storeId = selectedStoreId, price = 0) {
    let availableForShipping, availableForPickup;

    availableForShipping = bopis?.perSKU?.filter(stock => stock.sku == itemSKU).map(item => { return item.quantity }).reduce((partialSum, a) => partialSum + a, 0) >= quantity;
    if(isShippingStockLocation){
        availableForShipping = bopis?.perSKU?.filter(stock => stock.sku == itemSKU && shippingStockLocationIDs.indexOf(stock.storeId) >=0).map(item => { return item.quantity }).reduce((partialSum, a) => partialSum + a, 0) >= quantity;
    }

    if (isPDPPage() || isCheckoutPage() || isCartPage()) {
        availableForPickup = !!bopis?.perSKU?.find(perSKUStore =>
            (perSKUStore.storeId === storeId || !storeId) &&
            itemSKU === perSKUStore.sku &&
            perSKUStore.productAllowPickup &&
            bopis?.allStores?.find(s => s.locationId == storeId && s.pickupAllowed) &&
            quantity <= perSKUStore.quantity
        );
    }

    return { availableForShipping, availableForPickup };
}


export function getItemStock(itemSKU, storeId = '') {
    let stockCount = 0, stockCountInSelectedStore = 0, stockCountInWarehouse = 0, stockForPickup = 0;

    stockCount = bopis?.perSKU?.filter(stock => stock.sku == itemSKU && stock.locationAllowShipping).map(item => { return item.quantity }).reduce((partialSum, a) => partialSum + a, 0);
    
    stockCountInWarehouse = bopis?.perSKU?.filter(stock => stock.sku == itemSKU && (shippingStockLocationIDs.indexOf(stock.storeId) >=0)).map(item => { return item.quantity }).reduce((partialSum, a) => partialSum + a, 0);

    stockCountInWarehouse = stockCountInWarehouse <=0 ? 0:stockCountInWarehouse;
    
    stockForPickup = bopis?.perSKU?.filter(stock => stock.sku == itemSKU && stock.locationAllowPickup).length > 0 ? Math.max(...bopis?.perSKU?.filter(stock => stock.sku == itemSKU && stock.locationAllowPickup).map(item => { return item.quantity })) : 0;
    
    if(storeId && storeId.length > 0) {
        stockCountInSelectedStore = bopis?.perSKU?.filter(stock => stock.sku == itemSKU && stock.storeId == storeId)?.map(item => { return item.quantity })?.reduce((partialSum, a) => partialSum + a, 0) || 0;

    }

    return { stockCount, stockCountInSelectedStore, stockCountInWarehouse, stockForPickup };
}


export function render(placeholderSelector, content) {
    $(placeholderSelector).html(content);
}

export function closeDrawer() {
    if ($(".cnc__drawer").hasClass("opened")) {
        $(".cnc__drawer").removeClass("opened");
        $(".cnc__drawer-underlay").fadeOut(250);
        $("body").css("overflow", "auto");
    }
}

export function openDrawer() {
    $(".cnc__drawer-underlay").fadeIn(250);
    $(".cnc__drawer").addClass("opened");
    $("body").css("overflow", "hidden");
}

export function transformObject(obj) {
    if (!obj || ["string", "boolean", "number"].includes(typeof obj)) {
        return obj;
    }

    if (Array.isArray(obj)) {
        return obj.map(transformObject);
    } else if (obj.hasOwnProperty('edges')) {
        return obj.edges.map((edge) => transformObject(edge.node));
    }

    return Object.keys(obj).reduce((result, key) => {
        result[key] = transformObject(obj[key]);
        return result;
    }, {});
}

export function setCookie(cookieName, value, expireyDays = 7) {
    localStorage.setItem(cookieName, value);
    // var d = new Date();
    // d.setTime(d.getTime() + (expireyDays * 24 * 60 * 60 * 1000));
    // var expires = "expires=" + d.toUTCString();
    // document.cookie = cookieName + "=" + value + ";" + expires + ";path=/";
}

export function getCookie(cookieName) {
    return localStorage.getItem(cookieName) || null;
}

export function deleteCookie(name) {
    if (getCookie(name)) {
        localStorage.removeItem(name);
    }
}

export function removeComma(str, searchstr = ',') {
    var index = str.indexOf(searchstr);
    if (index === -1) {
        return str;
    }
    return str.slice(0, index) + str.slice(index + searchstr.length);
}

export function observeSections(selector) {
    return new Promise(function (resolve) {
        if (document.querySelector(selector)) {
            return resolve(document.querySelector(selector));
        }

        let observer = new MutationObserver(function (mutations) {
            if (document.querySelector(selector)) {
                resolve(document.querySelector(selector));
                //observer.disconnect();

            }
        });

        observer.observe(document.body, {
            childList: true,
            subtree: true
        });
    });
}

export function redirectToCartPage() {
    window.location = window.location.origin + "/cart.php";
}

export const objectsEqual = (o1, o2) => {
    return typeof o1 === 'object' && Object.keys(o1).length > 0
        ? Object.keys(o1).length === Object.keys(o2).length
        && Object.keys(o1).every(p => objectsEqual(o1[p], o2[p]))
        : o1 === o2;
}

export function showReplicaLoadingSkeleton(){
    $('.checkout-step--shipping .checkout-form').addClass('hide');
    hideReplicaLoadingSkeleton();
    $(`<div class="checkout-form-method" id="replica-loading-skeleton">
            <div class="address-form-skeleton">
                <div class="name-fields-skeleton">
                    <div class="skeleton-container">
                        <div class="input-skeleton"></div>
                    </div>
                    <div class="skeleton-container">
                        <div class="input-skeleton"></div>
                    </div>
                </div>
                <div class="skeleton-container">
                    <div class="input-skeleton"></div>
                </div>
            </div>
        </div>
    `).insertAfter($('.checkout-step--shipping .checkout-view-content .cnc__shipping-types'));
    if($('.checkout-step--shipping .checkout-view-content .cnc__shipping-types').length == 0){
        $('.checkout-step--shipping .checkout-view-content').prepend(`
            <div class="checkout-form-method" id="replica-loading-skeleton">
                <div class="address-form-skeleton">
                    <div class="name-fields-skeleton">
                        <div class="skeleton-container">
                            <div class="input-skeleton"></div>
                        </div>
                        <div class="skeleton-container">
                            <div class="input-skeleton"></div>
                        </div>
                    </div>
                    <div class="skeleton-container">
                        <div class="input-skeleton"></div>
                    </div>
                </div>
            </div>
        `);
    }
    // alert('show loader');
}

export function hideReplicaLoadingSkeleton(){
    $('#replica-loading-skeleton').remove();
}

export function showLoadingNotification() {
    let loadingHTML = `
    <div class="loadingNotification custom-notification">
        <div class="loadingNotification-label optimizedCheckout-loadingToaster">
            <div class="spinner">
            </div>
            <span aria-live="assertive" class="label" role="alert">Loading</span>
        </div>
    </div>`;
    if (!$(".loadingNotification").length)
        $(".checkout-steps").before(loadingHTML);
}

export function hideLoadingNotification() {
    $(".loadingNotification.custom-notification").remove();
}

export function isAccountPage() {
    if (location.pathname.includes("/account")) return true;
    else return false;
}

export function isAccountRewardsPage() {
    if (location.search.includes("rewards=1")) return true;
    else return false;
}

export function bindOnRewardsPage(){
    if(!custom__viewRewards) return;

    if(isAccountPage() && isAccountRewardsPage()){
        let checkIfRewardsBtnExist = setInterval(()=>{
            $('.breadcrumbs li:last span').html('GrangeRewards');
            $('.page-account .page-heading').html('GrangeRewards');
            $('.navBar-section li.navBar-item.is-active').removeClass('is-active');
            $('.account-body').fadeOut(250);
            $('main.page-content').addClass('is-loading');
            $('#cnc__grange-rewards').addClass('is-loading');
            if($('#cnc__grange-rewards').length > 0 && $('.account-rewards').length > 0){
                clearInterval(checkIfRewardsBtnExist);
                if($('#cnc__grange-rewards').parents('li.navBar-item').length > 0) $('#cnc__grange-rewards').parents('li.navBar-item').addClass('is-active');
                $('.account-rewards').removeClass('hide');
                if($('#account-pc-popup').hasClass('is-open')) $('#account-pc-popup').removeClass('is-open');
                $('main.page-content').removeClass('is-loading');
                $('#cnc__grange-rewards').removeClass('is-loading');
            }
        })
    }

    $('.navBar-section li.navBar-item').filter(function(e,i){return $(i).find('a').html() == 'GrangeRewards'}).each(function(e,i){
        $(i).find('a').attr('href', '#');
        $(i).find('a').attr('id', 'cnc__grange-rewards');
    });
    $("body").on("click", ".myAccount-link", (e) => {
        if($('.account-dropdown #btn-grange-rewards').length == 0){
            $('.account-dropdown .account-item:first-child()').after(`<li class="account-item">
                <button id="btn-grange-rewards" style="padding: 10px 0;"><span class="text">GrangeRewards</span></button>
            </li>`);
        }
    });
    $("body").on("click", "#cnc__grange-rewards, #btn-grange-rewards", (e) => {
        e.preventDefault();
        location.href = '/account.php?action=account_details&rewards=1';
    });
}