const { initCart } = require("./cart/cart");
const { initCheckout } = require("./checkout/checkout");
const { setCheckoutMetaData } = require("./checkout/checkoutData");
const { initWindowObj } = require("./common/initWindowObj");
const { getCookie, isOrderConfirmationPage } = require("./common/utils");
const { initGlobal } = require("./globalPage/globalPage");
const { setCustomerMetaData, checkCartItemsIfWithGC } = require("./globalPage/globalpageData");
const { initPDP } = require("./productDetailsPage/productDetailsPage");
const { setPDPMetaData } = require("./productDetailsPage/productDetailsPageData");
const { initPLP } = require("./productListingPage/productListingPage");
const { getBOPISData } = require("./services/randemRetailServices");

let devMode = getCookie("devMode") || null;
devMode = false;

if (devMode === "true") {
    return;
} else {
    cncDefer();

    function cncDefer() {
        if (window.jQuery) {
            init();
        } else {
            setTimeout(function () { cncDefer() }, 100);
        }
    }
}

async function init() {
    if (isOrderConfirmationPage()) {
        sessionStorage.removeItem("orderComments");
    }

    initWindowObj();
    await setCheckoutMetaData();
    await setPDPMetaData();
    await setCustomerMetaData();
    
    initGlobal.preHeader();
    initPDP.prepPDP();
    initCart.preCart();
    initCheckout.preCheckout();

    await getBOPISData();

    initPDP.loadPDP();
    initCart.loadCart();
    initCheckout.loadCheckout();
    await initGlobal.loadHeader();
    $('.cs-address-loader-hldr').hide();
    await checkCartItemsIfWithGC();
    window.isInitialBOPISLoad = false;
}