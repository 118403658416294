import { googleMapAPIKey } from "../common/globalConstants";
import { ICONS } from "../common/icons";
import { getCookie, removeComma, render } from "../common/utils";
import { setAvailableStoresCheckout, setStoreHours } from "../globalPage/globalpageData";
import { emptyShippingAddress, setShippingMethod } from "./checkoutData";

export const renderCheckoutSelectedStoreInfo = async () => {
    const isAvailbleSelectedStoreIdInfo = filteredStoresByAvailability.find(store => store?.locationId == selectedStoreId);
    const selectedStoreIdInfo = bopis?.allStores?.find(store => store?.locationId == selectedStoreId);
    const orderComments = sessionStorage.getItem("orderComments") || null;

    let selectedStoreInfoHTML = ``;
    if (selectedStoreIdInfo) {
        var combinedStoreHours = setStoreHours(selectedStoreIdInfo.operatingHours, selectedStoreIdInfo.locationId);
        selectedStoreInfoHTML = `
        
        <div class="selected-store-info">
            <div class="store-text-info">
                <p class="checkout-availability ${!isAvailbleSelectedStoreIdInfo ? 'cnc__color-unavailable' : 'cnc__color-available'}">
                    ${!isAvailbleSelectedStoreIdInfo ? 'Not Available for Pickup' : 'Available for Pickup'}
                </p>
                <p class="store-name">
                    ${selectedStoreIdInfo.storeName}
                </p>
                <p class="" store-address>
                    ${selectedStoreIdInfo.street?.length ? removeComma(selectedStoreIdInfo.street) + ", " : ""}${selectedStoreIdInfo.city}, ${selectedStoreIdInfo.state},
                    ${selectedStoreIdInfo.country}, ${selectedStoreIdInfo.zipCode}
                </p>
                <div class="operating-hours-section">
                    ${selectedStoreIdInfo.operatingHours && combinedStoreHours ?
                        `<span>Store Hours:</span>
                        ${combinedStoreHours?.map(day => {
                            return `<div class="store-days">
                                <span>${day.day}:</span>
                                ${day?.operatingHours?.length > 0 ?
                                    day?.operatingHours?.map(hour => {
                                        var fromTime = new Date('1970-01-01T' + hour.fromTime).toLocaleTimeString();
                                        var toTime = new Date('1970-01-01T' + hour.toTime)?.toLocaleTimeString();
                                        return `<p>${fromTime.split(fromTime.slice(-6, -2)).join('')} - ${toTime.split(toTime.slice(-6, -2)).join('')}</p>`
                                    }).join("")
                                : `<p>Closed</p>`}
                            </div>`
                        }).join("")}`
                    : ``}
                </div>
                ${selectedStoreIdInfo.phone ? `
                    <div class="store-phone-section">
                        <span>Contact Number:</span>
                        <div class="store-phone">
                            <div>
                                <a href="tel:${selectedStoreIdInfo.phone}">${selectedStoreIdInfo.phone}</a>
                            </div>
                        </div>
                    </div>`
                : ``}
                ${selectedStoreIdInfo.emailAddress ? `
                    <div class="store-phone-section" style="display:none;">
                        <span>Email</span>
                        <div class="store-phone">
                            <div>
                                <a href="mailto:${selectedStoreIdInfo.emailAddress}">${selectedStoreIdInfo.emailAddress}</a>
                            </div>
                        </div>
                    </div>`
                : ``}
            </div>
            <div class="store-map">
                <a class="change-selected-store">
                    <div class="icon">
                        ${ICONS.pinOutlineBlack}
                    </div>
                    <label>${selectedStoreIdInfo ? 'Change Pickup Store' : 'Select Pickup Store'}</label>
                </a>
                <iframe
                    width="100%"
                    height="250"
                    frameborder="0"
                    style="border:0"
                    referrerpolicy="no-referrer-when-downgrade"
                    src="https://www.google.com/maps/embed/v1/place/?key=${googleMapAPIKey}
                            &q=${encodeURIComponent(selectedStoreIdInfo.storeName)}
                            +${selectedStoreIdInfo.street}
                            +${selectedStoreIdInfo.city}
                            +${selectedStoreIdInfo.state}
                            +${selectedStoreIdInfo.country}
                            &center=${selectedStoreIdInfo.latitude},${selectedStoreIdInfo.longitude}&zoom=18"
                    allowfullscreen>
                </iframe>
            </div>
        </div>`
    }
    let preferredCheckoutMethod = getCookie("checkoutMethod") || "shipping";

    let HTMLToRender = `
        <div class="checkout-form-pickup ${!preferredCheckoutMethod || preferredCheckoutMethod === "shipping" || preferredCheckoutMethod === "sdd" ? "hide" : ""}">
            <div class="cnc__select-store-dropdown">
                <a class="change-selected-store" ${selectedStoreIdInfo ? `style="display:none;"`:``}>
                    <div class="icon">
                        ${ICONS.pinOutlineBlack}
                    </div>
                    <label>${selectedStoreIdInfo ? 'Change Pickup Store' : 'Select Pickup Store'}</label>
                </a>
                ${selectedStoreIdInfo ? selectedStoreInfoHTML : ``}

                ${selectedStoreIdInfo && isDatePickerEnabled == true ?
            `
                <div id="datePickerSectionWrapper">
                    <p style="margin-top: 15px; font-weight: bold; margin-bottom: 0;">Choose your pick-up slot</p>
                    <div id="datePickerSection">
                
                    <input type="text" style="display:none;">
                        <div id="datepicker-container">
                            <label class="form-label" for="allTimes">Date</label>
                            <input type="text" placeholder="Date to pick-up" class="form-input datePicker">
                        </div> 
                        <div id="timepicker-container" style="display: none;"> <label class="form-label" for="allTimes">Time </label>
                            <select name="allTimes" id="allTimes" class="form-select" >
                            </select>
                        </div>
                    </div>
                </div>
                `: ``}

                ${selectedStoreIdInfo && selectedStoreIdInfo.attributes.length && selectedStoreIdInfo.attributes.filter(a=>a.name == 'Pickup Option') ?`
                <div id="pickupMethodWrapper">
                    <div class="form-field">
                        <label for="pickupMethod" id="pickupMethod-label" class="form-label optimizedCheckout-form-label">Pickup Option</label>
                        <select aria-labelledby="pickupMethod-label pickupMethod-field-error-message" class="form-select optimizedCheckout-form-select" data-test="pickupMethod-select" id="pickupMethod" name="shippingAddress.pickupMethod">
                            <option value="">Select Method</option>
                            ${selectedStoreIdInfo.attributes.filter(a=>a.name == 'Pickup Option').map(a=>`<option>${a.value}</option>`)}
                        </select>
                    </div>
                </div>
                `:``}

                <fieldset class="form-fieldset" data-test="checkout-shipping-comments" ${selectedStoreIdInfo ? ``:`style="display:none;"`}>
                    <legend class="form-legend optimizedCheckout-headingSecondary">Order Comments</legend>
                    <div class="form-body">
                        <div class="form-field"><label for="orderComment" class="form-label is-srOnly optimizedCheckout-form-label">Order Comments</label>
                            <input autocomplete="off" maxlength="2000" type="text" class="form-input optimizedCheckout-form-input" name="orderComment" value="${orderComments?.length ? orderComments : ""}">
                            </div>
                        </div>
                </fieldset>

                ${selectedStoreIdInfo ? `
                <div class="form-actions">
                    <div class="cs-address-loader-hldr" style="display: none;"> <div class="cs-address-loader"></div> </div>
                    <button
                        id="cnc__fake-checkout-shipping-continue"
                        class="button button--primary optimizedCheckout-buttonPrimary"
                    >Continue</button>
                </div>`: ``}
            </div>
        </div >
        
    `;
    $(".checkout-form-pickup").remove();
    if (!$(".checkout-form-pickup").length) {
        $(".checkout-step--shipping .cnc__shipping-types").after(HTMLToRender);
        emptyShippingAddress();
    } else {
        $(".checkout-form-pickup").html(HTMLToRender);
    }


    if (isDatePickerEnabled == true) {
        var $datePicker = $('.datePicker');
        $datePicker.datepicker({
            format: 'yyyy-mm-dd',
            autoHide: true,
            startDate: new Date()
        });
    }
}

export const renderCheckoutCnC = async () => {
    return new Promise(async resolve => {
        let preferredCheckoutMethod = getCookie("checkoutMethod");

        if (!$(`.cnc__shipping-types`).length)
            $(".checkout-step--shipping .checkout-view-content").prepend(`
        <div class="cnc__shipping-types">
            ${isDeliveryMethodEnabled == true ? `
            <button 
                id="cnc__shipping-delivery"
                class="cnc__shipping-type-button button button button--secondary 
                ${preferredCheckoutMethod === "shipping" ? "active" : ""}">
                    DELIVERY / SHIPPING
            </button >
            `: ``}

            ${isPISMethodEnabled == true ? `
            <button
                id="cnc__shipping-pickup"
                class="cnc__shipping-type-button button button button--secondary 
                ${preferredCheckoutMethod === "pickup" ? "active" : ""}">
                    STORE PICKUP 
            </button >
            `: ``}

            ${isSDDMethodEnabled == true ? `
            <button
                id="cnc__same-day-delivery"
                class="cnc__shipping-type-button button button button--secondary 
                ${preferredCheckoutMethod === "sdd" ? "active" : ""}">
                    SAME DAY DELIVERY
            </button >
            `: ``}

        </div >
    `);

        await setShippingMethod();
        renderCheckoutSelectedStoreInfo();
        resolve()
    })
}

export const renderGrangeRewards = async () => {
    return new Promise(async resolve => {
        if(!customerMetaData){
            resolve();
            return;
        }
        let grangeRewards = customerMetaData.grangeRewardsDetails;
        $('.grange-rewards-checkout').remove();
        $(`aside.layout-cart .cart-section:nth-child(3), .ReactModalPortal .cart-section:nth-child(2)`).append(
            `<div class="grange-rewards-checkout">
                <span>GrangeRewards ${checkoutMetaData.cart.discountAmount > 0 && checkoutMetaData.cart.discountAmount <= grangeRewards.grangeRewardDollarAmount ? `<button class="btn-remove-grangerewards">(remove)</button>`: ``}</span>
                ${checkoutMetaData.cart.discountAmount > 0 && checkoutMetaData.cart.discountAmount <= grangeRewards.grangeRewardDollarAmount ? 
                    `<p class="grange-rewards-discount">-${checkoutMetaData.cart.currency.symbol}${(checkoutMetaData.cart.discountAmount).toLocaleString('en-US', {minimumFractionDigits:checkoutMetaData.cart.currency.decimalPlaces, maximumFractionDigits:checkoutMetaData.cart.currency.decimalPlaces})}</p>`
                    :`<div class="grange-rewards-box">
                        <p>You have $${grangeRewards.grangeRewardDollarAmount} in GrangeRewards</p>
                        <button class="btn-apply-grangerewards ${grangeRewards.grangeRewardDollarAmount == "0.00" ? `disabled`:``}">Apply</button>
                    </div>`
                }
            </div>`
        );
        await renderCartTotals();
        resolve();
    });
}

export const renderCartTotals = async () =>{
    return new Promise(async resolve => {
        $('[data-test="cart-discount"]').remove();
        $('[data-test="cart-total"] [data-test="cart-price-value"]').text(`${checkoutMetaData.cart.currency.symbol}${(checkoutMetaData.checkout.grandTotal).toLocaleString('en-US', {minimumFractionDigits:checkoutMetaData.cart.currency.decimalPlaces, maximumFractionDigits:checkoutMetaData.cart.currency.decimalPlaces})}`)
        resolve();
    });
}