import { amazonBaseUrl, xrandemappid } from "../common/globalConstants";
import { getCookie } from "../common/utils";

export const customServices = {
    validateGCNumber: async function (gcnumber) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: `${amazonBaseUrl}/api/Order/validateCardNumber`,
                headers: {
                    "Content-Type": "application/json",
                    "xrandemappid": xrandemappid,
                },
                method: "POST",
                dataType: "json",
                data: JSON.stringify({
                    "cardNumber": `${gcnumber}`
                }),
            }).then((responseData) => {
                resolve(responseData)
            }).always((err) => {
                resolve({message: 'invalid'});
            });
        });
    },
    getGrangeReward: async function (customerEmail) {
        return new Promise((resolve, reject) => {
            if(getCookie('rewardsTest')){
                customerEmail = getCookie('rewardsTest');
            }
            $.ajax({
                url: `${amazonBaseUrl}/api/Order/grangereward?emailaddress=${customerEmail}&phonenumber=&`,
                headers: {
                    "Content-Type": "application/json",
                    "xrandemappid": xrandemappid,
                },
                method: "GET"
            }).then((responseData) => {
                resolve(responseData)
            }).always((err) => {
                resolve({message: 'invalid'});
            });
        });
    },
    applyDiscount: async function (amount) {
        return new Promise(async (resolve, reject) => {
            $.ajax({
                method: "POST",
                url: `${amazonBaseUrl}/api/Order/discount?checkOutId=${checkoutMetaData.checkout.id}`,
                headers: {
                    "Content-Type": "application/json",
                    "xrandemappid": xrandemappid,
                },
                data: `{"cart":{"email": "${customerMetaData.email}", "discounts":[{"discounted_amount":${amount},"name":"grangerewards"}]}}`,
            }).then((responseData) => {
                resolve(responseData)
            }).always((err) => {
                resolve({message: 'invalid'});
            });
        });
    },
}