import { renderDrawerStores } from "../globalPage/globalPageRenderer";
import { setStore } from "../globalPage/globalpageData";
import { storefrontServices } from "../services/bigCommerceServices";
import { getBOPISData, checkSDDZone } from "../services/randemRetailServices";
import { ICONS } from "../common/icons";
const { renderPDPClickAndCollect } = require("./productDetailsPageRenderer");
const { areAllVariantsOptionsPicked, setPDPMetaData } = require("./productDetailsPageData");
const { getItemAvailability, isPDPPage } = require("../common/utils");

export function bindPDPEvents() {
    bindOnDOMSKUChange();
    bindPDPOnQuantityChange();
    bindAddtoCart();
    bindValidateRequiredVariant();
    bindSDDSection();
}

function bindSDDSection(){
    if(!isSDDMethodEnabled) return;
    var SDDtypingTimer;
    var SDDAddress;
    //TODO initialize google address finder
    $("body").on("keydown", "#sameDayDeliveryInput", function(){
        let $thisElement = $(this);
        clearTimeout(SDDtypingTimer);
        viewSDDLoader();
        SDDtypingTimer = setTimeout(function () {
            if(SDDAddress !== $thisElement.val() && $thisElement.val().length > 0){
                $('.sdd-eligible').removeClass('sdd-available').removeClass('sdd-unavailable');
                SDDAddress = $thisElement.val();
                checkSDDZone($thisElement.val()).then(async () => {
                    hideSDDLoader();
                    if(bopis.SDD.length > 0){
                        $('.sdd-eligible').addClass('sdd-available').html(`${ICONS.available} Available for Same Day Delivery for this address`);
                    }else{
                        $('.sdd-eligible').addClass('sdd-unavailable').html(`${ICONS.unavailable} Not available for Same Day Delivery for this address`);
                    }
                });
            }else{
                hideSDDLoader();
            }
        }, 2000);
    })
}

function viewSDDLoader(){
    hideSDDLoader();
    $('.sdd-eligible').hide();
    $(`<div class="placeholder_content sdd__item-availability-wrapper">
        <div class="placeholder_item"></div>
    </div>`).insertAfter('.sdd-eligible');
}

function hideSDDLoader(){
    $('.sdd__item-availability-wrapper').remove();
    $('.sdd-eligible').show();
}


function bindOnDOMSKUChange() {
    $("[data-product-option-change]").find("select, input").on("change", async () => {
        bopis = null;
        var productDetails = await storefrontServices.getProductSKU($('[name=product_id]').val(), $("[data-cart-item-add]").serialize());
        var updatedProductSKU;
        if (areAllVariantsOptionsPicked() == true) {
            let baseAttr = [];
            $('[data-product-option-change] [type="radio"]:checked').each(function (ind, elem) {
                let attrID = $(elem).attr('name').replace('attribute[', '').replace(']', '');
                let attrVal = $(elem).val();
                if (ind == 0) {
                    baseAttr = PDPMetaData.productVariants.filter(variant => variant.node.productOptions.find(productOption => productOption.entityId == attrID && productOption.values.find(value => value.entityId == attrVal)));
                } else {
                    baseAttr = baseAttr.filter(variant => variant.node.productOptions.find(productOption => productOption.entityId == attrID && productOption.values.find(value => value.entityId == attrVal)))
                }
            })
            updatedProductSKU = baseAttr[0].node.sku;
        }
        if (productDetails.data.sku) {
            $(".product-sku").html(productDetails.data.sku)
        }
    })

    const targetNode = document.querySelector("[data-product-sku]");
    if(targetNode){
        const config = { attributes: true, childList: true, subtree: true };
        const observer = new MutationObserver((mutationList, observer) => {
            checkPDPVariantAvailabilityHandler();
            console.log('change', $(".product-sku").text());
        });
    
        observer.observe(targetNode, config);
    }
}

export async function checkPDPVariantAvailabilityHandler() {
    bopis = null;
    renderPDPClickAndCollect();
    await setPDPMetaData();

    getBOPISData().then(async () => {
        handlePDPAddToCart();
        renderPDPClickAndCollect();
        renderDrawerStores();
    });
}

function bindPDPOnQuantityChange() {
    $("button[data-action]").on("click", () => {
        bopis = null;
        renderPDPClickAndCollect();

        setTimeout(() => {
            checkPDPVariantAvailabilityHandler();
        }, 10)
    })

    var typingTimer;
    $(".form-input--incrementTotal").on("change", (e) => {
        disablePDPAddToCart();
        clearTimeout(typingTimer);
        typingTimer = setTimeout(function () {
            if($(e.currentTarget).val() == '' || $(e.currentTarget).val() == 0 || $(e.currentTarget).val().length == 0) {
                $('#cnc__product-delivery-type > h6').html('Please input quantity');
                $('#cnc__product-delivery-type .cnc__shipping-availability').hide();
                $('#cnc__product-delivery-type .cnc-selected-store-info').hide();
                return;
            }
            bopis = null;
            renderPDPClickAndCollect();

            setTimeout(() => {
                checkPDPVariantAvailabilityHandler();
            }, 10)
        }, 500);
    });
}

function bindAddtoCart() {
    $("body").on("click", "#form-action-addToCart", () => {
        if (PDPMetaData.productHasVariants) {
        }
    })
}

function bindValidateRequiredVariant() {
    $("#form-action-addToCart").on("click", () => {
        if (!$(".option-select").val()?.length) {
            $(".custom-dropdown-select").addClass("dropdown-required-error");
        } else {
            $(".custom-dropdown-select").removeClass("dropdown-required-error");
        }
    })

    $("body").on("click", ".custom-dropdown-options li", () => {
        $(".custom-dropdown-select").removeClass("dropdown-required-error");
    })
};


export function handlePDPAddToCart() {
    if (!isPDPPage()) return;

    const { availableForShipping, availableForPickup } = getItemAvailability(PDPMetaData.selectedProduct.SKU, PDPMetaData.selectedProduct.quantity);
    if (!(availableForShipping || (selectedStoreId && availableForPickup))) {
        disablePDPAddToCart();
    } else {
        enablePDPAddToCart();
    }
}

function disablePDPAddToCart() {
    $("#form-action-addToCart").addClass("disabled");
}

function enablePDPAddToCart() {
    $("#form-action-addToCart").removeClass("disabled");
}

export function PDPsetVariants() {
    if (!isPDPPage() || PDPMetaData.productHasVariants == false) return;
    $('.form-option').attr('disabled', 'disabled').addClass('unavailable');
    $('.form-radio').attr('disabled', 'disabled').addClass('unavailable');

    PDPMetaData.productVariants.forEach(variant => {
        variant.node.productOptions.forEach(option => {
            $('[data-product-attribute-value=' + option.values[0].entityId + ']').addClass(variant.node.sku);
            $(':input[value="' + option.values[0].entityId + '"]').addClass(variant.node.sku);
        });
    });

    setTimeout(() => {
        crossOutVariants();
    }, 3000);
}

function crossOutVariants() {
    setInterval(() => {
        $('.form-option').attr('disabled', 'disabled').addClass('unavailable');
        $('.form-radio').attr('disabled', 'disabled').addClass('unavailable');
        bopis?.perSKU.forEach(variant => {
            if (variant.quantity > 0) {
                $("." + variant.sku + "").removeAttr('disabled').removeClass('unavailable');
            }
        });
    }, 300);
}