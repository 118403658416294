
import { CheckoutObserver } from "../common/observer";
import { Popup } from "../common/popUp";
import { getCookie, observeSections, redirectToCartPage, setCookie } from "../common/utils";
import { renderDrawerStores } from "../globalPage/globalPageRenderer";
import { setStore } from "../globalPage/globalpageData";
import { storefrontServices } from "../services/bigCommerceServices";
import { getBOPISData } from "../services/randemRetailServices";
import { emptyShippingAddress, setCheckoutMetaData, setShippingMethod, setStoreDetailsAsShippingDetails } from "./checkoutData";
import { renderCheckoutCnC, renderCheckoutSelectedStoreInfo, renderGrangeRewards  } from "./checkoutRenderer";
import { customServices } from "../services/customServices";

let checkoutObserver = new CheckoutObserver();

export function bindCheckoutEvents() {
    bindOnShippingOptionClick();
    bindOnPickupOptionClick();
    bindOnCnCSelectChange();
    bindOnFakeCheckoutShippingContinue();
    bindStepsCTAListeners();
    bindOnTimeSlotChange();
    bindOnGCChange();
}

export function bindOnGCChange(){
    if(!custom__validateGC) return;
    var GCtypingTimer;
    $("body").on("keyup", ".coupon-form #couponcode, #redeemable-collapsable .optimizedCheckout-form-input, .gift-certificate-code .form-input", async (e) => {
        clearTimeout(GCtypingTimer);
        $('#applyRedeemableButton, input[value="Apply"]').addClass('disabled');
        GCtypingTimer = setTimeout(async function () {
            let validateCardNumber = await customServices.validateGCNumber($(e.currentTarget).val());
            if(validateCardNumber.message.toLowerCase() == 'valid'){
                $('#applyRedeemableButton, input[value="Apply"]').removeClass('disabled');
            }
        },1000)
    });
}

export function checkoutAfterUpdaterListenerInit() {
    if (checkoutObserver.isShippingAddressOpened()) {
        if ($('.cnc__shipping-types').length == 0) {
            renderCheckoutCnC();
        }
    }
    if (checkoutObserver.whenCheckoutShippingLoads()) {
        let preferredCheckoutMethod = getCookie("checkoutMethod") || "shipping";;

        if (preferredCheckoutMethod == 'shipping') {
            $('#checkout-shipping-options .pickup-in-store').hide();
            $('.shippingOptions-container ul li[class*="pickup"]').hide();
            $('.shippingOptions-container ul li[class*="same"]').hide();
        }

        let isPISselectedShippingOption = $('#checkout-shipping-options input[type=radio]:checked').next('label').text().toLowerCase().indexOf('pickup') >= 0 || $('#checkout-shipping-options input[type=radio]:checked').length == 0;
        if (preferredCheckoutMethod == 'shipping' && isPISselectedShippingOption == true) {
            $('.shippingOptions-container ul li:not([class*="pickup"]):not([class*="same"]):first input[type=radio]').trigger('click');
            $('.shippingOptions-container ul li:not([class*="pickup"]):not([class*="same"]):first div').trigger('click');
        }
    }
}

export function loadersInCheckout() {
    $('.checkout-step--shipping .checkout-form').addClass('hide');
    if ($('.cs-address-loader-hldr').length == 0) {
        $('.checkout-step--shipping .checkout-view-content').prepend(`<div class="cs-address-loader-hldr"> <div class="cs-address-loader"></div> </div>`)
    }
}

export function checkoutUpdaterListenerInit() {
    if (checkoutObserver.isShippingAddressOpened()) {
        const $checklistItems = $(".shippingOptions-container .form-checklist-item");

        $checklistItems.each((index, item) => {
            const shippingOptionTextToClass = $(item).find(".shippingOption-desc").text().trim().toLowerCase().replaceAll(" ", "-");
            $(item).addClass(shippingOptionTextToClass);
        });
    }
}

function bindOnShippingOptionClick() {
    $("body").on("click", "#cnc__shipping-delivery", async () => {
        $("#cnc__shipping-delivery").addClass("active");
        $("#cnc__shipping-pickup").removeClass("active");
        $("#cnc__same-day-delivery").removeClass("active");

        setCookie("checkoutMethod", "shipping", 7);


        // Go back to single shipping mode if the split shipping was already clicked
        if ($(`[data-test="shipping-mode-toggle"]`).text() === "Ship to a single address") {
            document.querySelector(`[data-test="shipping-mode-toggle"]`)?.click();
        }

        $('.checkout-step--shipping .checkout-form').fadeOut();
        $('.checkout-form-pickup').fadeOut();


        checkoutObserver.checkBillingSameAsShipping();
        emptyShippingAddress();
        await setShippingMethod();
        await storefrontServices.removeConsignment();
    });
}

function bindOnPickupOptionClick() {
    $("body").on("click", "#cnc__shipping-pickup", async () => {
        $("#cnc__shipping-pickup").addClass("active");
        $("#cnc__shipping-delivery").removeClass("active");
        $("#cnc__same-day-delivery").removeClass("active");

        setCookie("checkoutMethod", "pickup", 7);

        renderCheckoutSelectedStoreInfo();

        checkoutObserver.unCheckBillingSameAsShipping();
        checkoutObserver.unCheckSaveShippingAsAddress();
        await setShippingMethod();
        await storefrontServices.removeConsignment();
    });
}

function bindOnCnCSelectChange() {
    $("body").on("click", "[data-location-id], .cnc__set-store-btn", async (e) => {
        let locationId = $(e.target)?.attr("store-id");
        if (locationId) {
            getBOPISData().then(async () => {
                await setStore(locationId, true, true);
                emptyShippingAddress();
                renderCheckoutSelectedStoreInfo();
                checkoutObserver.unCheckBillingSameAsShipping();
                checkoutObserver.unCheckSaveShippingAsAddress();
            });
        }
    });
}

function bindOnFakeCheckoutShippingContinue() {
    $("body").on("click", "#cnc__fake-checkout-shipping-continue", async function () {
        var notAllowedPickup = checkoutMetaData.products.filter(function(prd){
            return bopis.perSKU == null || bopis.perSKU.find(sku=> sku.sku == prd.sku && sku.quantity >= prd.quantity && sku.storeId == selectedStoreId) == undefined
        });
        if (notAllowedPickup.length > 0) {
            storefrontServices.removeConsignment();
            let headerText = "Not Available Items";
            let contentHTMLul = '';
            notAllowedPickup.forEach(function (el, ind) {
                contentHTMLul = contentHTMLul + '<li>' + el.name + '</li>';
            });
            let contentHTML = `
            <p>Some items are unavailable for pickup in selected store</p>
            <ul>
                ${contentHTMLul}
            </ul>`;
            checkoutUnavailabilityPopup.prepare({
                headerText: headerText,
                contentHTML: contentHTML,
                button1Text: "Change Pickup Store",
                button2Text: "Go To Cart",
                button3Text: "Change Store",
                onButton1Click: () => {
                    checkoutUnavailabilityPopup.close();
                    $('.change-selected-store').trigger('click');
                },
                onButton2Click: () => {
                    redirectToCartPage();
                    checkoutUnavailabilityPopup.close();
                },
            });

            checkoutUnavailabilityPopup.open();
        } else {
            if (isDatePickerEnabled == true && (($('.datePicker').val() == null || $('.datePicker').val().length == 0) || ($('#allTimes').val() == null || $('#allTimes').val().length == 0))) {
                let contentHTML = "Please select your preferred pickup time";
                checkoutUnavailabilityPopup.prepare({
                    headerText: "No Timeslot Selected",
                    contentHTML: contentHTML,
                    button1Text: "OKAY",
                    onButton1Click: () => {
                        checkoutUnavailabilityPopup.close();
                    }
                });

                checkoutUnavailabilityPopup.open();
                return;
            }
            await setStoreDetailsAsShippingDetails(selectedPickupStore);
        }
    });

    $("body").on("click", "#checkout-shipping-continue", (e) => {
        let preferredCheckoutMethod = getCookie("checkoutMethod") || "shipping";
        let isPISselectedShippingOption = $('#checkout-shipping-options input[type=radio]:checked').next('label').text().toLowerCase().indexOf('pickup') >= 0;

        if (preferredCheckoutMethod == 'shipping' && isPISselectedShippingOption == true) {
            let contentHTML = "Please select your preferred Shipping Method";
            if ($('#checkout-shipping-options li').length >= 1) {
                contentHTML = "Please try other Shipping Address";
            }
            checkoutUnavailabilityPopup.prepare({
                headerText: "No Shipping Method Selected",
                contentHTML: contentHTML,
                button1Text: "OKAY",
                onButton1Click: () => {
                    checkoutUnavailabilityPopup.close();
                }
            });

            checkoutUnavailabilityPopup.open();

            storefrontServices.removeConsignment();
            e.preventDefault();
        }
    });
}

function bindStepsCTAListeners() {
    document.getElementById("checkout-page-container").addEventListener("click", async function (e) {
        // e.target was the clicked element
        if (e.target && (e.target.matches(`.checkout-step--shipping .stepHeader-actions .button--tertiary`)
            || e.target.matches(`#checkout-customer-continue`))) {
            await observeSections('.checkout-form form #checkoutShippingAddress');
            await setCheckoutMetaData();
            renderDrawerStores();
            await renderCheckoutCnC();
            renderCheckoutSelectedStoreInfo();
        }
    });
}

/* *********************************************** */
// #region DATEPICKER FUNCTIONS
function bindOnTimeSlotChange() {
    if (isDatePickerEnabled == false) return;
    const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    $("body").on('change', '.datePicker', function () {
        $('#cnc__fake-checkout-shipping-continue').hide();
        $("#timepicker-container").hide();
        $("#pickup-comments").hide();
        $('#allTimes').find('option').remove().end();

        const dis = new Date($(this).val());
        const dateToday = new Date();
        let day = weekday[dis.getDay()];

        var selecteddate = dis.getUTCFullYear() + "-" + pad((parseInt(dis.getUTCMonth()) + 1), 2) + "-" + pad(dis.getUTCDate(), 2);
        var dateTodayDate = dateToday.getFullYear() + "-" + pad((parseInt(dateToday.getMonth()) + 1), 2) + "-" + pad(dateToday.getDate(), 2);
        var selectedtime = pad(dateToday.getHours(), 2) + ":" + pad(dateToday.getMinutes(), 2);

        let newselecteddate = new Date(selecteddate + 'T' + selectedtime);
        day = weekday[newselecteddate.getDay()];

        const selectedStoreIdInfo = bopis.allStores.find(store => store?.locationId == selectedStoreId);
        let getSchedule = selectedStoreIdInfo?.operatingHours?.find(per => per.day === day);

        if (getSchedule?.operatingHours.length > 0) {
            let fromDate = new Date(selecteddate + 'T' + getSchedule?.operatingHours[0].fromTime);
            let from = fromDate.getHours();

            let toDate = new Date(selecteddate + 'T' + getSchedule?.operatingHours[0].toTime);
            let to = toDate.getHours();

            let isValid = true;

            if ($(this).val() == dateTodayDate) {
                console.log(fromDate.getHours(), "form")
                console.log(newselecteddate.getHours(), "form")
                console.log(newselecteddate, "form")
                // if (fromDate.getHours() + 2 <= newselecteddate.getHours()) {
                if (fromDate.getHours() <= newselecteddate.getHours() + 2) {
                // if (fromDate.getHours() + 2 <= newselecteddate.getHours() && newselecteddate.getHours()) {
                    from = newselecteddate.getHours() + 2;
                    if (newselecteddate.getMinutes() > 0) {
                        from = from + 1;
                    }
                }
                if (from >= to) {
                    isValid = false;
                }
            }

            $('#allTimes').append("<option disabled selected>Select a time</option>");
            if (isValid == true) {
                for (let i = from; i < to; i++) {
                    $("#allTimes").append("<option value='" + i + ":00'>" + toStandardTime(i + ":00") + "</option>");
                    $("#allTimes").append("<option value='" + i + ":30'>" + toStandardTime(i + ":30") + "</option>");
                }

                $("#timepicker-container").show();
                $("#pickup-comments").show();
                $('#cnc__fake-checkout-shipping-continue').show();
            } else {
                showTimeSlotUnavailable();
            }

        } else {
            showTimeSlotUnavailable();
        }
    });
}

function toStandardTime(militaryTime) {
    militaryTime = militaryTime.split(':');
    if (militaryTime[0].charAt(0) == 1 && militaryTime[0].charAt(1) == 2) {
        return '12:' + militaryTime[1] + 'PM';
    } else if (militaryTime[0].charAt(0) == 1 && militaryTime[0].charAt(1) > 2) {
        return (militaryTime[0] - 12) + ':' + militaryTime[1] + 'PM';
    } else {
        return (militaryTime.join(':') + 'AM');
    }
}

function showTimeSlotUnavailable() {
    let contentHTML = "Please select other date to show timeslot availabililty";
    checkoutUnavailabilityPopup.prepare({
        headerText: "No Timeslot Available",
        contentHTML: contentHTML,
        button1Text: "OKAY",
        onButton1Click: () => {
            checkoutUnavailabilityPopup.close();
        }
    });

    checkoutUnavailabilityPopup.open();
    $('#cnc__fake-checkout-shipping-continue').hide();
}

function pad(str, max) {
    str = str.toString();
    return str.length < max ? pad("0" + str, max) : str;
}

// #endregion DATEPICKER FUNCTIONS
/* *********************************************** */

/* *********************************************** */
// #region GrangeRewards FUNCTIONS
export function bindOnGrangeRewards(){
    $('body').on('click', '.btn-apply-grangerewards', async function(e){
        e.preventDefault();
        $(e.currentTarget).addClass('is-loading');
        let appliedDiscount = await customServices.applyDiscount(customerMetaData.grangeRewardsDetails.grangeRewardDollarAmount);
        checkoutMetaData.cart = await storefrontServices.getCart();
        checkoutMetaData.cart = checkoutMetaData.cart[0];
        checkoutMetaData.checkout = await storefrontServices.getCheckOutInformation(checkoutMetaData.cart.id);
        checkoutUnavailabilityPopup.prepare({
            headerText: "Cart Discount",
            contentHTML: `Your GrangeRewards has been applied`,
            button1Text: "Okay",
            onButton1Click: () => {
                location.reload();
            },
            customClass: 'success'
        });

        swal.fire({
            icon: 'success',
            text: 'Your GrangeRewards has been applied',
            showConfirmButton: true,
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                location.reload();
            }
        });

        // checkoutUnavailabilityPopup.open();
        await renderGrangeRewards();
    })
    
    $('body').on('click', '.btn-remove-grangerewards', async function(e){
        $(e.currentTarget).parents('.grange-rewards-checkout').addClass('is-loading');
        let appliedDiscount = await customServices.applyDiscount(0);
        checkoutMetaData.cart = await storefrontServices.getCart();
        checkoutMetaData.cart = checkoutMetaData.cart[0];
        checkoutMetaData.checkout = await storefrontServices.getCheckOutInformation(checkoutMetaData.cart.id);
        await renderGrangeRewards();
        $('[data-test="cart-discount"]').remove();
        location.reload();
    });
}
/* *********************************************** */