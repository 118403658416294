import { getCookie, hideLoadingNotification, hideReplicaLoadingSkeleton, isCartPage, isCheckoutPage, redirectToCartPage, showLoadingNotification, showReplicaLoadingSkeleton } from "../common/utils";
import { storefrontServices } from "../services/bigCommerceServices";
import { graphQLServices } from "../services/graphQLServices";

export async function setCheckoutMetaData() {
    if(!isCheckoutPage() && !isCartPage()) return;
    return new Promise(async resolve => {
        cartMetaData.cartProducts = [];
        checkoutMetaData.products = [];
        var cartItemDetails = await storefrontServices.getCart();
        cartItemDetails = cartItemDetails[0];
        checkoutMetaData.unavailablePISItems = [];
        checkoutMetaData.cart = cartItemDetails;
        cartMetaData.cartProducts = checkoutMetaData.products = cartItemDetails?.lineItems.physicalItems?.map(item => {
            return { lineitemId:item.id, sku: item.sku, quantity: item.quantity, name: item.name, productId: item.productId }
        });

        checkoutMetaData.productsWithItemID = cartItemDetails?.lineItems.physicalItems?.map(item => {
            return { itemId: item.id, quantity: item.quantity }
        });

        let checkoutInfo = checkoutMetaData.checkout = await storefrontServices.getCheckOutInformation(cartItemDetails.id);
        checkoutMetaData.channelID = checkoutInfo.channelId;
        checkoutMetaData.productsInfo = await graphQLServices.getProductDataByIds();
        checkoutMetaData.pickupOnlyProducts = checkoutMetaData.productsInfo.filter(pr => pr.customFields.find(cf=>cf.name == 'User Code U4' && (cf.value=='@' || cf.value=='=')));
        resolve();
    });
}

export async function setShippingMethod() {
    return new Promise(async resolve => {
        let preferredCheckoutMethod = getCookie("checkoutMethod");

        if (preferredCheckoutMethod?.length > 0) {
            showReplicaLoadingSkeleton();
            showLoadingNotification();
            $('.checkout-step--shipping .checkout-form').addClass('hide');
            $('.checkout-form-pickup').addClass('hide');
        }

        // await storefrontServices.removeConsignment();

        if (isDeliveryMethodEnabled == true && preferredCheckoutMethod == 'shipping') {
            let warehouseStocks = bopis.perSKU.filter(store=>shippingStockLocationIDs.indexOf(store.storeId) >=0);
            let isOutOfStockInWH = checkoutMetaData.products.filter(product=> warehouseStocks.filter(wh=>wh.sku == product.sku).map(item => { return item.quantity }).reduce((partialSum, a) => partialSum + a, 0) < product.quantity);
            if(isOutOfStockInWH.length > 0 || checkoutMetaData.pickupOnlyProducts.length > 0){
                checkoutUnavailabilityPopup.prepare({
                    headerText: "Not Available Items",
                    contentHTML: `
                    ${isOutOfStockInWH.length > 0 ? `
                    <div class="cnc__delivery-not-available">
                        <p>Some items are not available in stock</p>
                        <ul>
                            ${isOutOfStockInWH.map(item=>{return `<li>${item.name}</li>`}).join("")}
                        </ul>
                    </div>
                    `:``}
                    
                    ${checkoutMetaData.pickupOnlyProducts.length > 0 ? `
                    <div class="cnc__delivery-not-available">
                        <p>Some items are too big and heavy for Normal delivery <br/>and will only be available for Pick-up in store.</p>
                        <ul>
                            ${checkoutMetaData.pickupOnlyProducts.map(item=>{return `<li>${item.name}</li>`}).join("")}
                        </ul>
                    </div>
                    `:``}
                    `,
                    button1Text: "Switch to Pickup",
                    button2Text: "Go To Cart",
                    onButton1Click: () => {
                        checkoutUnavailabilityPopup.close();
                        $('#cnc__shipping-pickup').trigger('click');
                        if(checkoutMetaData.pickupOnlyProducts.length > 0) $('#cnc__shipping-delivery').toggleClass('disabled');
                    },
                    onButton2Click: () => {
                        redirectToCartPage();
                        checkoutUnavailabilityPopup.close();
                    },
                });

                checkoutUnavailabilityPopup.open();

                await storefrontServices.removeConsignment();
            }else{
                $('.checkout-step--shipping .checkout-form').removeClass('hide');
                $('.checkout-step--shipping .checkout-form').show();
                $('.checkout-form-pickup').addClass('hide');
                $('.checkout-form-sdd').addClass('hide');
                $('.cs-address-loader-hldr').hide();
            }
        }

        if (isPISMethodEnabled == true && preferredCheckoutMethod == 'pickup') {
            $('.checkout-form-pickup').removeClass('hide');
            $('.checkout-form-pickup').fadeIn();
            $('.checkout-step--shipping .checkout-form').addClass('hide');
            $('.checkout-form-sdd').addClass('hide');
            $('.cs-address-loader-hldr').hide();
        }

        hideReplicaLoadingSkeleton();
        hideLoadingNotification();
        resolve();
    })
}

export function emptyShippingAddress() {
    const $shippingFormSelects = $(".checkout-step--shipping form select");
    const $shippingFormInputs = $(".checkout-step--shipping form input");

    $shippingFormSelects.each((selectIndex, select) => {
        checkoutObserver.changeSelectValue(select, "")
    });

    $shippingFormInputs.each((inputIndex, input) => {
        checkoutObserver.changeValue($(input).get(0), '');
    });
}

export async function setStoreDetailsAsShippingDetails(selectedPickupStore) {
    return new Promise(async resolve => {
        $('#cnc__fake-checkout-shipping-continue').toggleClass('is-loading');
        let cartItemDetails = checkoutMetaData.cart;
        const orderCommentsFromSession = sessionStorage.getItem("orderComments") || null;
        var products = [];

        products = cartItemDetails?.lineItems.physicalItems?.map(item => {
            return { itemId: item.id, quantity: item.quantity }
        });
        selectedPickupStore = bopis.allStores?.find(store => store?.locationId === selectedStoreId);
        let checkoutDetails = await storefrontServices.getCheckOutInformation(cartItemDetails.id);

        let orderComments = $(`[name="orderComment"]`).val();

        if (orderComments?.length) {
            await storefrontServices.updateCustomerMessage(cartItemDetails.id, {
                "customerMessage": orderComments
            })
        } else {
            if (orderCommentsFromSession?.length) {
                sessionStorage.removeItem("orderComments");
                await storefrontServices.updateCustomerMessage(cartItemDetails.id, {
                    "customerMessage": ""
                })
            }
        }

        var data = {
            "address": {
                "address1": selectedPickupStore.street?.replace(",", "")?.trim() || "",
                "address2": selectedPickupStore.storeName + " Store",
                "city": selectedPickupStore.city,
                "company": "",
                "country": selectedPickupStore.country,
                "countryCode": $('#countryCodeInput').find('option').filter(function () { return $(this).html().toLowerCase() == selectedPickupStore.country.toLowerCase(); }).val(),
                "firstName": selectedPickupStore.storeName,
                "lastName": 'Store',
                "phone": selectedPickupStore.phone,
                "postalCode": selectedPickupStore.zipCode,
                "shouldSaveAddress": false,
                "stateOrProvince": selectedPickupStore.state
            },
            "lineItems": products
        }

        if (pickupStoreFieldID.length > 0) {
            data.address.customFields ? data.address.customFields.push({
                "fieldId": pickupStoreFieldID,
                "fieldValue": selectedStoreId
            }) : data.address.customFields = [{
                "fieldId": pickupStoreFieldID,
                "fieldValue": selectedStoreId
            }];
        }

        if (isDatePickerEnabled == true) {
            data.address.customFields ? data.address.customFields.push({
                "fieldId": pickupTimeslotFieldID,
                "fieldValue": $('.datePicker').val() + " " + $('#allTimes').val()
            }) : data.address.customFields = [{
                "fieldId": pickupTimeslotFieldID,
                "fieldValue": $('.datePicker').val() + " " + $('#allTimes').val()
            }];
        }

        if(selectedPickupStore.attributes.length && selectedPickupStore.attributes.filter(a=>a.name == 'Pickup Option') && $('#pickupMethod option:selected').val().length > 0){
            data.address.customFields ? data.address.customFields.push({
                "fieldId": pickupMethodFieldID,
                "fieldValue": $('#pickupMethod option:selected').val()
            }) : data.address.customFields = [{
                "fieldId": pickupMethodFieldID,
                "fieldValue": $('#pickupMethod option:selected').val()
            }];
        }else{
            data.address.customFields ? data.address.customFields.push({
                "fieldId": pickupMethodFieldID,
                "fieldValue": ""
            }) : data.address.customFields = [{
                "fieldId": pickupMethodFieldID,
                "fieldValue": ""
            }];
        }


        var thisConsignment;
        if (checkoutDetails.consignments.length > 0) {
            let checkoutConsignment = await storefrontServices.updateConsignment(cartItemDetails.id, checkoutDetails.consignments[0].id, data);
            thisConsignment = checkoutConsignment.consignments[0];
        } else {
            let checkoutConsignment = await storefrontServices.updateShippingAddress(cartItemDetails.id, [data]);
            thisConsignment = checkoutConsignment.consignments[0];
        }
        if (thisConsignment.availableShippingOptions.length == 0) {
            $('#cnc__fake-checkout-shipping-continue').toggleClass('is-loading');
        } else {
            var thisPickupMethod = thisConsignment.availableShippingOptions.filter(function (con) {
                return con.description.toLowerCase().indexOf('pickup') >= 0 || con.description.toLowerCase().indexOf('pick up') >= 0;
            });
            if (thisPickupMethod.length > 0) {
                thisPickupMethod = thisPickupMethod[0];
                var shippingDetailsUpdates = await storefrontServices.updateConsignment(cartItemDetails.id, thisConsignment.id, { "shippingOptionId": thisPickupMethod.id });
                location.reload();
            } else {
                $('#cnc__fake-checkout-shipping-continue').toggleClass('is-loading');
                console.log('no pickup method');
            }
        }

        resolve();
    })
}
