import { setCheckoutMetaData } from "../checkout/checkoutData";
import { observeSections } from "../common/utils";
import { getBOPISData } from "../services/randemRetailServices";
import { setCartMetaData } from "./cartData";
import { renderCartItemAvailability } from "./cartRenderer";

export function bindCartOnFormChange() {
    var onLoadPrice = $('.cart-total-grandTotal span').text();
    $('body').on('click', '.cart-remove.icon, button[data-cart-update]', function () {
        let haveChangeTotal = setInterval(() => {
            if ($('.cart-total-grandTotal span').text() !== onLoadPrice) {
                clearInterval(haveChangeTotal);
                location.reload();
            }
        }, 500);
    });

    observeSections('.shipping-quotes ul').then((elm) => {
        setInterval(()=>{
            if ($('.shipping-quotes ul').length > 0) {
                $('.shipping-quotes label.estimator-form-label-text').each(function () {
                    var labelName = $(this).text();
                    if (labelName.toLowerCase().indexOf('pickup') >= 0) {
                        $(this).parents('li.estimator-form-row').remove();
                    }
                });
            }
        }, 100);
    });
    $("body").on("click", "[data-cart-update], .cart-remove", (e) => {
        $(".page-content").on("DOMNodeInserted", (e) => {
            let isFormLoadedInterval = setInterval(async () => {
                if ($('.cart-container .loadingOverlay').length > 0 && $('.cart-container .loadingOverlay').attr('style').indexOf('none') >= 0) {
                    clearInterval(isFormLoadedInterval);
                    $(".page-content").off("DOMNodeInserted");

                    bopis = null;
                    await setCheckoutMetaData();

                    getBOPISData().then(() => {
                        renderCartItemAvailability();
                    })
                }
            }, 50);
        });
    })

    $("body").on("change", ".form-input--incrementTotal", (e) => {
        let haveChangeTotal = setInterval(() => {
            if ($('.cart-total-grandTotal span').text() !== onLoadPrice) {
                clearInterval(haveChangeTotal);
                location.reload();
            }
        }, 500);
    })
}