const { BIGCOMMERCE_PLACEHOLDERS, GENERATED_PLACEHOLDERS, staticCustomizations } = require("../common/globalConstants");
const { getItemAvailability, render, removeComma, getItemStock } = require("../common/utils");
const { ICONS } = require("../common/icons");

export const renderPDPClickAndCollect = () => {
    let HtmlToRender;
    let currentStockInSelectedStore = 0;

    if (!bopis?.perSKU) {
        HtmlToRender = `
        <div class="placeholder_content">
            <div class="placeholder_item"></div>
        </div>
        `;
    } else {
        $('.placeholder_content').remove();

        if (PDPMetaData.productHasVariants && !PDPMetaData.areAllVariantsOptionsPicked) {
            HtmlToRender = `<div id="cnc__product-delivery-type" class="storeselectwidget"><p><b>${staticCustomizations.PDPTitle}</b></p><p>${staticCustomizations.NoSelectedVariants}</p>`;
        } else {
            let { availableForShipping, availableForPickup } = getItemAvailability(PDPMetaData.selectedProduct.SKU, PDPMetaData.selectedProduct.quantity, selectedStoreId, PDPMetaData.selectedProduct.price);

            let { stockCount, stockCountInSelectedStore, stockCountInWarehouse, stockForPickup } = getItemStock(PDPMetaData.selectedProduct.SKU, selectedStoreId);
            currentStockInSelectedStore = stockCountInSelectedStore;
            console.log(bopis.perSKU, stockCount, stockCountInSelectedStore, stockCountInWarehouse, stockForPickup);
            let currentOnlineStock = stockCountInWarehouse;

            console.log(PDPMetaData.productInfo, PDPMetaData.isPickupOnly)

            HtmlToRender =
                `<h6>${staticCustomizations.PDPTitle}</h6>
                ${PDPMetaData.isPickupOnly == true ? `<p class="cnc__shipping-availability cnc__color-unavailable">${ICONS.unavailable} Not Available for Shipping</p>`:availableForShipping && currentOnlineStock > 0
                    ? `<p class="cnc__shipping-availability cnc__color-available">${ICONS.available} ${staticCustomizations.OnlineAvailabilityLabel} <span class="in-stock">Current Stock: ${currentOnlineStock}</span></p>`
                    : `<p class="cnc__shipping-availability cnc__color-unavailable">${ICONS.unavailable} ${staticCustomizations.NotOnlineAvailabilityLabel}</p>`}
                
                ${!selectedStore?.locationId ?
                    `<p>${staticCustomizations.PDPNoStoreSelected}</p>` :
                    availableForPickup
                        ? `<p class="cnc__shipping-availability cnc__color-available in-store">${ICONS.available} ${staticCustomizations.PISAvailabilityLabel} <span class="in-stock">Current Stock: ${currentStockInSelectedStore}</span></p>`
                        : `<p class="cnc__shipping-availability cnc__color-unavailable">${ICONS.unavailable} ${staticCustomizations.NotPISAvailabilityLabel}</p>`}
                    
                ${!selectedStore?.locationId
                    ? ``
                    : `<div class="cnc-selected-store-info">
                        <h6>${selectedStore.storeName}</h6>
                        <p>${removeComma(selectedStore.street)}</p>
                        <p>${selectedStore.city}, ${selectedStore.zipCode}</p>
                        </div>`

                } 
                
                <a class="button button--secondary button-small cnc__select-store">
                    ${selectedStore?.locationId ? "CHANGE STORE LOCATION" : "SELECT A STORE"}
                </a>`;
        }

        if(isSDDMethodEnabled == true && $(`${GENERATED_PLACEHOLDERS.productPageSDDDeliveryType}`).length == 0){
            $(`
            <div id="cnc__product-sdd-delivery-type" class="delivery-section-selector">
                <div class="section-header">
                    <label for="sameDayDelivery">
                        <span><b>SAME DAY DELIVERY</b></span>
                        <span class="poweredby-uber">POWERED BY ${ICONS.uber}</span>
                    </label>
                </div>
                <div class="section-content">
                    <p class="variantAvailability" style="display:none;">Select a variant to view product availability </p>
                                        
                    <input type="text" id="sameDayDeliveryInput" placeholder="Enter your address">
                    <p class="sdd-eligible">Enter your address to see if you are eligible for Same Day Delivery</p>
                    <div class="sameDayDeliveryResult" style="display: none;">
                        <div class="custom-loader" style="display: none;"></div>
                        <span class="availability-text"><strong class="availability"></strong> for Same Day Delivery for this address</span>
                    </div>
                </div>
            </div>
            `).insertAfter($(GENERATED_PLACEHOLDERS.productPageDeliveryType));
        }

    }

    if (!$(GENERATED_PLACEHOLDERS.productPageDeliveryType).length) {
        $(`<div id='${GENERATED_PLACEHOLDERS.productPageDeliveryType.substring(1)}' ></div> `).insertAfter($(BIGCOMMERCE_PLACEHOLDERS.productATC));
    }

    render(GENERATED_PLACEHOLDERS.productPageDeliveryType, HtmlToRender);
    if (currentStockInSelectedStore == 0) {
        showAlternativeStores();
    }
}

export function showAlternativeStores() {
    if (bopis?.perSKU?.length == 0 || bopis?.perSKU == undefined) return;

    let otherStores = bopis?.perSKU.filter(stock => PDPMetaData.selectedProduct.SKU == stock.sku && stock.quantity >= parseInt(PDPMetaData.selectedProduct.quantity)).map(store => {
        return `<li><a class="cnc__set-store-btn" href="#" store-id="${store.storeId}">${store.storeName}</a></li>`
    }).join("");


    $('.alternative-stores-hldr').remove();
    if (otherStores.length > 0) $('.cnc-selected-store-info').append('<div class="alternative-stores-hldr"><p>Currently stock in:</p><ul class="alternative-stores">' + otherStores + '</ul></div>')
}