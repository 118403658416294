
export const isLiveSite = window.location.hostname?.includes("sandbox") || window.location.hostname?.includes("local") ? false : true;

export const applicationId = isLiveSite ? "f30fcd1f-f80b-4941-a7d7-9ce5bf72ab78" : "2af4f54f-2270-4bb5-9226-1518b8392b72";

export const api_baseurl = isLiveSite ? 'https://api.randemretail.online/public/api/' : 'https://uat-api.randemretail.online/public/api/';

export const sdd_api_baseurl = isLiveSite ? 'https://api.randemretail.online/public/api/distance/calculate' : 'https://uat-api.randemretail.online/public/api/distance/calculate';

export const googleMapAPIKey = "AIzaSyC3pgFDJCjPmZdRu15G58Cx-dlJvkOlf_M";

export const amazonBaseUrl = isLiveSite ? 'https://m7kgpg72w5.execute-api.eu-west-2.amazonaws.com':'https://534qp2kxif.execute-api.eu-west-2.amazonaws.com';

export const xrandemappid = 'r@nd3m-gR@ng3!';

export const BIGCOMMERCE_PLACEHOLDERS = {
    headerDesktop: ".header .item--account",
    headerMobile: "#main-content",
    productATC: ".productView-actions",
}

export const GENERATED_PLACEHOLDERS = {
    headerDesktop: "#cnc__header-placeholder-desktop",
    headerMobile: "#cnc__header-placeholder-mobile",
    productPageDeliveryType: "#cnc__product-delivery-type",
    productPageSDDDeliveryType: "#cnc__product-sdd-delivery-type",
};

export const staticCustomizations = {
    PDPNoStoreSelected: "Please select store to view availability",
    PickupButtontext: "Set your Store",
    PickupButtonSecondarytext: "Find your local store",
    NoSelectedVariants: "Please select variant to view product availability",
    OnlineAvailabilityLabel: "Available for Shipping",
    NotOnlineAvailabilityLabel: "Not enough stock for shipping",
    PISAvailabilityLabel: "Available In-Store",
    NotPISAvailabilityLabel: "Not enough stock in-store",
    PDPTitle: "Delivery Options",
    CheckoutShippingStepHeaderText: "Select Preferred Checkout",
    CartOOSInPIS: "Not Enough Stocks for Pickup in",
    CartAvailInPIS: "Available for Pickup",
    CartAvailInDel: "Available for Shipping",
    CartOOSInDel: "Not Enough Stocks for Shipping",
};

export const storesOrderingList = ["Grants Pass", "South Medford", "Central Point", "Klamath Falls", "Yuba City", "White City", "Ashland"];